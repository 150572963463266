export default {
  default: {
    stop_livestream_msg: "Are you sure you want to stop this livestream?",
    leave_stream_msg: "Are you sure you want to leave this stream?",
    leave_stream: "Leave stream",
    stop_stream: "Stop Stream",
    cancel: "Cancel",
    clone: "Clone",
    close_event: "Are you sure you want to Clone this event?",
    delete_event: "Are you sure you want to delete this event?",
    select_ticket:  "Please Select a ticket",
    re_enter: "Re-enter ",
    invalid_file_msg: "File type not allowed",
    confirm: "Confirm",
    transfer_msg_1: "Transfer a ticket",
    transfer_msg_2: "so no one misses out on the event!"
  },
  en: {
    stop_livestream_msg: "Are you sure you want to stop this livestream?",
    leave_stream_msg: "Are you sure you want to leave this stream?",
    leave_stream: "Leave stream",
    stop_stream: "Stop Stream",
    cancel: "Cancel",
    clone: "Clone",
    close_event: "Are you sure you want to Clone this event?",
    delete_event: "Are you sure you want to delete this event?",
    re_enter: "Re-enter",
    select_ticket:  "Please Select a ticket",
    invalid_file_msg: "File type not allowed",
    confirm: "Confirm",
    transfer_msg_1: "Transfer a ticket",
    transfer_msg_2: "so no one misses out on the event!",
    field_cannot_be_blank: "This field cannot be blank",
    events: {
      form: {
        please_select_an_option: "Please select an option",
        create: "CREATE",
        go_live: "GO LIVE",
        save: "SAVE"
      }
    },
    bluesnap: {
      authentication_error: "Something went wrong with your 3DS check",
      error: "Something went wrong"
    }
  },
  ja: {
    stop_livestream_msg: "このライブストリームを停止してもよろしいですか",
    leave_stream_msg: "このストリームを終了してもよろしいですか？",
    leave_stream: "ストリームを離れる",
    stop_stream: "ストリームを停止する",
    cancel: "キャンセル",
    clone: "クローン",
    close_event: "このイベントのクローンを作成してもよろしいですか？",
    delete_event: "このイベントを削除してもよろしいですか？",
    select_ticket:  "チケットを選択してください"
  },
  ko: {
    stop_livestream_msg: "이 실시간 스트리밍을 중지하시겠습니까?",
    leave_stream_msg: "이 스트림에서 나가시겠습니까?",
    leave_stream: "스트림 나가기",
    stop_stream: "스트림 중지",
    cancel: "취소",
    clone: "클론",
    close_event: "이 이벤트를 복제하시겠습니까?",
    delete_event: "이 일정을 삭제하시겠습니까?",
    select_ticket:  "티켓을 선택하세요"
  }
}