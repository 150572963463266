import { Controller } from 'stimulus'; 
var currentPage;

export default class extends Controller {
  static targets = ["pagination"]

  scroll(e) {
    e.stopImmediatePropagation();
    const nextPage =  $(this.paginationTarget).find("a[rel='next']")[0];
    if (nextPage == null) return;
    if (currentPage == nextPage) return;
    var height = Math.max(this.element.scrollHeight, this.element.offsetHeight)
    if ($(this.element).scrollTop() >= height - $(this.element).innerHeight()) {
      console.log(`About to click nextPage: ${nextPage}`);
      nextPage.click();
      currentPage = nextPage;
    }
  }

}


