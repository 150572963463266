import { Controller } from 'stimulus'; 
import i18n  from "../packs/i18n"

export default class extends Controller {
  static values = {
    eventId: String
  }

  stop_livestream(e){
    var event_id = this.eventIdValue;
    bootbox.confirm({
      closeButton: false,
      message: i18n.t('stop_livestream_msg'),
      buttons: {
        confirm: {
            label: i18n.t('stop_stream'),
            className: 'btn-primary'
        },
        cancel: {
            label: i18n.t('cancel'),
            className: 'btn-light'
        }
      },
      callback: async function(confirmed) {
        if (confirmed) {
          stopStream(event_id)
        }
      }
    });
  }

  leave_livestream(e) {
    var event_id = this.eventIdValue;
    bootbox.confirm({
      closeButton: false,
      message: i18n.t('leave_stream_msg'),
      buttons: {
          confirm: {
              label: i18n.t('leave_stream'),
              className: 'btn-primary'
          },
          cancel: {
              label: i18n.t('cancel'),
              className: 'btn-light'
          }
      },
      callback: async function (result) {
        if(result){
          leaveStream(event_id)
        }
      }
    });
  }
}


