import { Controller } from "stimulus"
import { get } from "@rails/request.js"
import i18n  from "../packs/i18n"

export default class extends Controller {

  static targets = ["external", "payoutform",
    "external", "confirmation","payoutForm","externalTypeSelect",
    "externalId", "externalIdConfirmation", "bankingPreferences"
  ]

  static values = {
    url: String,
    param: String,
    loadForm: String,
    ccForm: String
  }

  connect() {  
    console.log("payout connect");
    
    if(!this.loadFormValue && !this.ccForm){
      $('.modal').modal('hide')
    }
    
    var provider = $('.providers_radio_buttons:checked').val();
    if(provider) {  
      this.get_external_types(provider,true)
    }else{
      this.placeholder_text('email');
    }
   
  }

  setDefaultPayout(e){
    let params = new URLSearchParams()
    params.append("id", e.currentTarget.dataset.payout)
      fetch(`${this.urlValue}?${params}`)
      .then(response => response.json())
      .then(data => {
        $('#banking_preferences').find('li span.icon-span').css('color', 'grey');
        $('#banking_preferences').find('li span.list-group-span').html('')
        $('#payout_'+data.id).css('color', 'green');
        $('#default_payout_'+data.id).html('default')
      })
  }

  external_types(event) {
    this.get_external_types(event.target.value,false)
    $('label.collection_radio_buttons').removeClass('payout-active')
    $("[for="+event.target.id+"]").addClass('payout-active');
  }

  get_external_types(provider,autoLoad) {
    this.payoutformTarget.removeAttribute("style");
    let params = new URLSearchParams()
    let type = autoLoad ? sessionStorage.getItem('external_type') : 'email'
    params.append(this.paramValue, provider)
    params.append("type", type)
    get(`${this.urlValue}?${params}`, {
      responseKind: "turbo-stream"
    })
    this.placeholder_text(type)
  }

  change_placeholder_text(event){
    if( !event ) event = window.event;
    let type = event.target.value; 
    this.placeholder_text(type);
  }

  placeholder_text(value){
    if (this.hasExternalIdTarget && this.hasExternalIdConfirmationTarget) {
      this.externalIdTarget.innerHTML =  this.capitalize(value);
      this.externalIdConfirmationTarget.innerHTML =  i18n.t('confirm')+" "+this.capitalize(value)
      sessionStorage.setItem("external_type",value);
    }
  }

  capitalize(str){
    return str.charAt(0).toUpperCase() + str.slice(1);
  }


}