import { Controller } from 'stimulus'; 
export default class extends Controller {

  // TO DO: change target names to camelCase
  static targets = [
    "generalPrice", "vipPrice",
    "generalTicketServiceFee", "vipTicketServiceFee",
    "changePriceLink", "upgradeTotalPrice", "upgradeOrder",
    "updateOrderDetails"
  ]

  static values = {
    debounceQtyPlus: Number,
    debounceQtyMinus: Number
  }

  connect() {
    // var credit_card_added = $('#credit_card_added').val();
    // var payment_option  = "";
    // console.log(credit_card_added);
    // if (credit_card_added == "true" ) {
    //   payment_option = "existing_cards";
    // }else{ 
    //   payment_option = "new_card";  
    // }

    $('input[id=zevents_event_order_general_ticket_count]').on('change', function(){
      if ($(this).val() < 0 || $(this).val() == '') {
        $(this).val('0');
      }
    });

    $('input[id=zevents_event_order_vip_ticket_count]').on('change', function(){
      if ($(this).val() < 0 || $(this).val() == '') {
        $(this).val('0');
      }
    });

    $('input[id=zevents_event_order_general_price]').on('change', function(){
      if ($(this).val() < 0 || $(this).val() == '') {
        $(this).val('0.00');
      }
    });

    $('input[id=zevents_event_order_vip_price]').on('change', function(){
      if ($(this).val() < 0 || $(this).val() == '') {
        $(this).val('0.00');
      }
    });
    

    // console.log(payment_option);
    // $(`input[type=radio][value=${payment_option}]`).prop("checked", true);
    // var target = $("#" + payment_option);
    // $(".credit-cards").not(target).hide();
    // $(target).show();

    // $('input[type=radio][name=credit_card_option]').click(function(){
    //   var inputValue = $(this).attr("value");
    //   var target = $("#" + inputValue);
    //   $(".credit-cards").not(target).hide();
    //   $(target).show();
    // });

    this.totalAmount > 0 ? $('.credit-card-section').show() : $('.credit-card-section').hide();

  }

  update_order_details(event) {
    const element = event.target;
    var fieldName = element.dataset.field;
    var fieldId = element.dataset.tickettype;
    this.update_order_details_in_event_order_controller(fieldName, fieldId);
  }

  update_order_details_in_event_order_controller(fieldName, fieldId) {
    var paymentType = $('#myTab input:radio:checked').val();
    console.log(fieldName);
    var count  = parseInt($(`#${fieldName}`).val());
    var price = parseFloat($(`#${fieldName}_price`).val());  
    var params = `?count=${count}&ticketType=${fieldId}&paymentType=${paymentType}`

    // TODO - Have to check if below 'price' is being accepted by server-side code as is from client-side,
    // because it could be security issue - user could manipulate the 'price' being sent from client-side.
    if (!isNaN(price)) {
      params = params + '&price=' + price
    } 
    var update_order_link = $(this.updateOrderDetailsTarget)
    var url = update_order_link.attr('href');
    url = url + params;
    update_order_link.attr('href',url);
    update_order_link[0].click();
    if (this.totalAmount > 0) {
      $(".credit-card-section").show();
    } else {
      $(".credit-card-section").hide();
    }
  }

  update_currency() {
    var currency = $('#currency_rate_dropdown').val();
    // var currency = currencyRate.split("|")[0];
    var params = '?currency=' + currency;
    var update_currency_link = $('#update_currency_link');
    var url = update_currency_link.attr('href');
    url = url + params;
    update_currency_link.attr('href',url);
    update_currency_link[0].click();
  }

  update_order_totals(event) {
    var fieldId = $(".ticket-count-container")[0].dataset.ticketTypeId;
    var fieldName = "zevents_order_ticket_type_" + fieldId;

    
      $('.event_order').show();
    

    this.update_order_details_in_event_order_controller(fieldName, fieldId);
  }

  qtyplus(event){
    const element = event.target;
    var fieldName = element.dataset.field;
    // Get its current value
    var currentVal = parseInt($('input[id='+fieldName+']').val());
    // If is not undefined
    if (!isNaN(currentVal)) {
      // Increment
      $('input[id='+fieldName+']').val(currentVal + 1);
    } else {
      // Otherwise put a 0 there
      $('input[id='+fieldName+']').val(0);
    }
    // if (this.debounceQtyPlusValue) {
    //   // Cancel prev Plus operations
    //   window.clearTimeout(this.debounceQtyPlusValue);
    //   this.debounceQtyPlusValue = null;
    // }
    // this.debounceQtyPlusValue = setTimeout(() => {
      this.update_order_details(event);
    // }, 250);
  }  

  qtyminus(event){
    const element = event.target;
    var fieldName = element.dataset.field;
    // Get its current value
    var currentVal = parseInt($('input[id='+fieldName+']').val());
  // If it isn't undefined or its greater than 0
    if (!isNaN(currentVal) && currentVal > 0) {
      // Decrement one
      $('input[id='+fieldName+']').val(currentVal - 1);
    } else {
      // Otherwise put a 0 there
      $('input[id='+fieldName+']').val(0);
    }

    // if (this.debounceQtyMinusValue) {
    //   // Cancel prev Minus operations
    //   window.clearTimeout(this.debounceQtyMinusValue);
    //   this.debounceQtyMinusValue = null;
    // }
    // this.debounceQtyMinusValue = setTimeout(() => {
      this.update_order_details(event);
    // }, 250);
  }
  
  get totalAmount() {
    let total_amount = 0;
    if (this.upgradeOrderTarget.value == 'true') {
      total_amount = parseFloat(this.upgradeTotalPriceTarget.value)
    } else {
      total_amount = parseFloat($("#total_amount_calc").html());
    }
    return total_amount;
  }

  apply_credits(){
    var avail_user_credits = $("#credit_amount_check").is(':checked');
    var total_amount = 0;
    if (this.upgradeOrderTarget.value == 'true') {
      total_amount = parseFloat(this.upgradeTotalPriceTarget.value)
    } else {     
      total_amount = parseFloat($("#total_amount_calc").html());
      console.log($("#total_amount_calc").html());
    }
    var credit_amount_to_be_applied = this.calculate_credit_amount_to_be_applied(total_amount);
    console.log(`credit_amount_to_be_applied = ${credit_amount_to_be_applied}`);
    console.log(`total_amount = ${total_amount}`);
    $(".credit_amount").html(credit_amount_to_be_applied);
    if (avail_user_credits ) {
      $("#total_amount").html("$"+total_amount.toFixed(2) );
      total_amount = total_amount - credit_amount_to_be_applied; 
      if (total_amount <= 0) {
        $(".credit-card-section").hide();
      }
      $("#display_credit_amount_applied").show();
    } else {
      $("#total_amount").html("$"+total_amount.toFixed(2) );
      if (total_amount > 0) {
        $(".credit-card-section").show();
      }
      $("#display_credit_amount_applied").hide();
    }

  }

  calculate_credit_amount_to_be_applied(total_price){

   var user_available_credits = $(`input[type=checkbox][name=credit_amount_check]`).val() ;  
   var avail_user_credits = $("#credit_amount_check").is(':checked');
   var credit_amount_to_be_applied = 0;

    if (user_available_credits < 0) {
      credit_amount_to_be_applied = 0
    }else if (total_price > user_available_credits) {
      credit_amount_to_be_applied = user_available_credits
    } else {
      credit_amount_to_be_applied = total_price
    }
    console.log(`calculate_credit_amount_to_be_applied(${total_price}) = ${credit_amount_to_be_applied}`);
    return credit_amount_to_be_applied;
  }

  update_addons_details(event){
    var ticket_type_id = event.target.dataset.tickettype;
    var price  = $(`#zevents_order_ticket_type_price_${ticket_type_id}`).val();
    var count  = $(`#zevents_order_ticket_type_count_${ticket_type_id}`).val();
    var params = `?count=${count}&ticketType=${ticket_type_id}`
    if (!isNaN(price)) {
      params = params + '&price=' + price
    } 
    var update_order_link = $(this.updateOrderDetailsTarget)
    var url = update_order_link.attr('href');
    url = url + params;
    update_order_link.attr('href',url);
    update_order_link[0].click();
  }
  

  change_upgrade_charges(event){   
    const element = event.target;
    var ticket_type_id = $('.upgradation_checks:checked').data('tickettype');
    var price  = $("#zevents_order_ticket_type_price").val();
    $("#zevents_order_ticket_type_id").val(ticket_type_id);
    this.ticket_upgradation(price, ticket_type_id);     
 
   }

   update_ticket_type_in_upgradation(event){
    const element = event.target;
    var ticket_type_id = element.dataset.tickettype;
    var checked_ticket_price = element.dataset.ticketprice;
    var checked_ticket_name = element.dataset.ticketname;
    $(".upgradation_checks").prop("checked", false);
    $("#price_per_ticket").html(checked_ticket_price);
    $("#zevents_order_ticket_type_price").val(checked_ticket_price);
    $('#ticket_name').html(checked_ticket_name);
    $(`#zevents_event_order_${ticket_type_id}upgradation`).prop("checked", true);
    $("#zevents_order_ticket_type_id").val(ticket_type_id);
    this.ticket_upgradation(checked_ticket_price, ticket_type_id);   
   }

  ticket_upgradation(upgrade_price, ticket_type_id){
    var paid_amount = $("#paid-amount").data('paidamount')
    var change_price_url = this.changePriceLinkTarget.href;
    change_price_url = change_price_url.split('?')[0];

    var params = `?tickettype=${ticket_type_id}` ;
    if (!isNaN(upgrade_price)) {
      params = params + `&price=${upgrade_price}`      
    } 
    change_price_url = change_price_url + params;

    this.changePriceLinkTarget.href = change_price_url;
    this.changePriceLinkTarget.click();
    if (!isNaN(upgrade_price)) {    
      if (parseInt(upgrade_price) <= parseInt(paid_amount)) {
        $(".credit-card-section").hide();
        $("#credits-toggle").hide();
      } else {
        $(".credit-card-section").show();
        $("#credits-toggle").show();
      }
    }  
  
  }
}
