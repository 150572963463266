import { Controller } from 'stimulus';
import i18n from "../packs/i18n";

var myInterval;
export default class extends Controller {

  static targets = [
    "updateEventDetails"
  ]

  static values = {
    eventId: String,
    futureEvent: Boolean,
    eventStartDate: String,
    timerShow: String,
    eventTicketId: String
  }

  disconnect() {
    window.clearInterval(myInterval);
  }

  connect() {
    if (this.timerShowValue && this.futureEventValue) {
      this.set_count_down(this.eventStartDateValue, this.eventTicketIdValue)
    }
  }

  set_count_down(future_time, event_ticket_id) {
    var update_event_detail = $(this.updateEventDetailsTarget)

    myInterval = setInterval(function() {
      // Find the distance between now and the count down date
      var countdown = (future_time - new Date().getTime());
      // Time calculations for days, hours, minutes and seconds
      var days = Math.floor(countdown / (1000 * 60 * 60 * 24));
      var hours = Math.floor((countdown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      var minutes = Math.floor((countdown % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((countdown % (1000 * 60)) / 1000);
      var colon = ":";
      hours  = ('0' + hours).slice(-2);
      minutes = ('0' + minutes).slice(-2);
      seconds = ('0' + seconds).slice(-2);
      $("#ticket_countdown_timer").html("<div></div>  " + "<div><span class='gradianted-color'>" + days + "<small>Days</small></span></div>" + "<div><span class='gradianted-color'>" + colon + "</span></div>" + "<div><span class='gradianted-color'>" + hours + "<small>Hours</small></span></div>" + "<div><span class='gradianted-color'>" + colon + "</span></div>" + "<div><span class='gradianted-color'>" + minutes + "<small>Minutes</small></span></div>" + "<div><span class='gradianted-color'>" + colon + "</span></div>" + "<div><span class='gradianted-color'>" + seconds + "<small>Seconds</small></span></div>");
      if (countdown < 0) {
        clearInterval(myInterval);
        $("#ticket_countdown_timer").html("");
        update_event_detail[0].click();
      }
    }, 1000);
  }
}
