import { Controller } from 'stimulus';
import QrCodeReader from "../qr_code_scanner"
export default class extends Controller {

  static values = {
    eventId: String
  }

  connect() {
    function qrReaderCallback(result) {
      console.log('scanned QR code - ' + result);
    }

    console.log('initializing qr code reader');
    window.codeReaderWrapper = new QrCodeReader(qrReaderCallback);

    $('#startButton').off('click');
    $('#startButton').on('click', () => {
      window.codeReaderWrapper.start(this.eventIdValue);
      this.hideCameraIcon();
    });

    $('#resetButton').off('click');
    $('#resetButton').on('click', () => {
      window.codeReaderWrapper.reset();
      this.showCameraIcon();
    })

    $('#flipButton').off('click');
    $('#flipButton').on('click', () => window.codeReaderWrapper.flip());

    $(document).off('hidden.bs.modal', '#' + this.element.id);
    $(document).on('hidden.bs.modal', '#' + this.element.id,  function(e) {
      console.log('resetting qr code reader')
      window.codeReaderWrapper.reset();
    })
  }

  hideCameraIcon() {
    $('.vdo-gray-box svg.scann-img-cam').hide();
  }

  showCameraIcon() {
    $('.vdo-gray-box svg.scann-img-cam').show();
  }

}


