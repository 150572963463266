import { Controller } from 'stimulus';
import * as clipboard from "clipboard-polyfill/text";
export default class extends Controller {
  static values = { eventUrl: String, profileUrl: String, promoCode: String }

  copy_text(event) {

    if(this.eventUrlValue){
      clipboard.writeText(this.eventUrlValue).then(
        () => { toastr.info("Event url copied.");},
        () => { toastr.error("Error copying url, Try again later.."); }
      );
    }

    if(this.profileUrlValue){
      clipboard.writeText(this.profileUrlValue).then(
        () => { toastr.info("Profile url copied.");},
        () => { toastr.error("Error copying url, Try again later.."); }
      );
    }

    if(this.promoCodeValue){
      clipboard.writeText(this.promoCodeValue).then(
        () => { toastr.info("Promo code copied.");},
        () => { toastr.error("Error copying promo code, Try again later.."); }
      );
    }
    
  }
    
}


