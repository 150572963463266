// stimulus.js:2785 Error connecting controller

// TypeError: toastr[type] is not a function
//     at extended.connect (flash_controller.js:14:1)
//     at Context.connect (stimulus.js:1845:1)
//     at Module.connectContextForScope (stimulus.js:2112:1)
//     at Router.scopeConnected (stimulus.js:2594:1)
//     at ScopeObserver.elementMatchedValue (stimulus.js:2492:1)
//     at ValueListObserver.tokenMatched (stimulus.js:1355:1)
//     at TokenListObserver.tokenMatched (stimulus.js:1239:1)
//     at stimulus.js:1224:1
//     at Array.forEach (<anonymous>)
//     at TokenListObserver.tokensMatched (stimulus.js:1223:1)


import { Controller } from 'stimulus'; 
export default class extends Controller {

  connect(){
    let type = this.element.dataset.flashType;
    let message = this.element.dataset.flashMessage;

    // Auto remove the flash messages
    setTimeout(() => {
      this.element.remove();
    }, 5000);

    // Create Tostr flash messages
    toastr[type](message, '', { closeButton: true });
  }
}


