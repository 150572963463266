import { Controller } from 'stimulus'; 

export default class extends Controller {
  static values = {
    eventId: String
  }

  jitsi(event) {
    console.log("event id " + this.eventIdValue)
    event.preventDefault()
    event.stopImmediatePropagation()
    window.TurboNativeBridge.postMessage("jitsi", {"eventId": this.eventIdValue})
  }
}
