export default class QrCodeReader {
  constructor(callback) {

    this.codeReader = new ZXing.BrowserQRCodeReader()
    console.log('ZXing code reader initialized')
    this.selectedDeviceId = null
    this.qrReaderCallback = callback
    this.videoInputDevices = [];

    navigator.mediaDevices.getUserMedia({video: true})
    .then(() => {
      this.codeReader.getVideoInputDevices()
      .then((videoInputDevices) => {
        if (videoInputDevices.length == 0) {
          bootbox.alert("No Camera Found");
          return;
        }

        this.videoInputDevices = videoInputDevices;
        const sourceSelect = document.getElementById('sourceSelect')
        // Let us select the Back Camera as the default camera as it makes more sense for scanning QR Codes
        const lastDeviceIndex = videoInputDevices.length -1;
        this.selectedDeviceId = videoInputDevices[lastDeviceIndex].deviceId
        console.log('videoInputDevices: ', this.videoInputDevices);
        console.log('selectedDeviceId = ', this.selectedDeviceId);

        videoInputDevices.forEach((element) => {
          const sourceOption = document.createElement('option')
          sourceOption.text = element.label
          sourceOption.value = element.deviceId
          sourceSelect.appendChild(sourceOption)
        })

        sourceSelect.onchange = () => {
          this.selectedDeviceId = sourceSelect.value;
          console.log('selectedDeviceId = ', this.selectedDeviceId);
        };

        if (videoInputDevices.length == 1) {
          $('#flipButton').attr('disabled', true).css('opacity', '0.3');
        }

        // if (this.videoInputDevices.length > 0) {
        //   document.getElementById('startButton').click();
        // }
      })
      .catch((err) => {
        console.error(err);        
      })
    });
  }

    start(eventId) {
      console.log('eventId', eventId, 'selectedDeviceId', this.selectedDeviceId);
      this.codeReader.decodeFromInputVideoDevice(this.selectedDeviceId, 'video').then((result) => {
        console.log(result);
        if (result.text.startsWith("scan_ticket-")) {
          this.codeReader.reset();
          let barcode_uid = result.text.split('scan_ticket-')[1];
          let ticket_id   = result.text.split("-")[2];
          let url         = `/v2/event_ticket/${ticket_id}/scan_ticket?stamp=${Date.now()}&event_id=${eventId}&barcode_uid=${barcode_uid}`;

          console.log(`Ticket url = ${url}`);

          $("#scan_ticket").attr("href",url);
          $("#scan_ticket")[0].click();
          $('#qr_scanner_turbo_modal').modal('hide'); // Hide modal popup
          $('.collapse:not(#collapseTwo)').collapse('hide'); // Expand Tickets section and collapse the other sections
          $('#collapseTwo').collapse('show');
        } else {
          var https_redirect_url = result.text;
          if (!result.text.startsWith('http')) {
            https_redirect_url = 'https://' + result.text;
          } else if (result.text.startsWith("http:")) {
            https_redirect_url  = result.text.replace("http", "https");
          }

          Turbo.visit(https_redirect_url)
        }
			}).catch((err) => {
			  console.error(err);
			})
      console.log("Started decode from camera with id" + this.selectedDeviceId)
    }

    reset() {
      this.codeReader.reset()
      // document.getElementById('result').textContent = '';
      console.log('Reset.');
      // toastr.success('Reset..Please click start button to Scan the QR Code');
    }

    flip() {
      if (this.videoInputDevices && (this.videoInputDevices.length > 1)) {
        this.codeReader.reset();
        // Change to next Camera if number of Cameras is > 1
        let indexOfSelectedDevice = this.videoInputDevices.findIndex(x => x.deviceId == this.selectedDeviceId);
        // indexOfSelectedDevice += 1;
        // if (indexOfSelectedDevice >= this.videoInputDevices.length) {
        indexOfSelectedDevice -= 1;
        if (indexOfSelectedDevice < 0) {
          // Let us select the Back Camera as the default camera as it makes more sense for scanning QR Codes
          const lastDeviceIndex = this.videoInputDevices.length -1;
          indexOfSelectedDevice = lastDeviceIndex;
        }
        const selectedDeviceId = this.videoInputDevices[indexOfSelectedDevice].deviceId;
        const sourceSelect = document.getElementById('sourceSelect');
        $(sourceSelect).val(selectedDeviceId).change();

        // Click on Start button
        document.getElementById('startButton').click();
      }
    }
  }