import { Controller } from "stimulus"
import i18n from "../packs/i18n"

export default class extends Controller {

  static values = {
    bluesnapToken: String,
    amount: Number,
    currency: String,
    doFraudCheck: Boolean, // 3DS
    paymentMethod: String
  }

  connect() {
    var bluesnapToken = this.bluesnapTokenValue;

    console.log(`bluesnap token = ${this.bluesnapTokenValue}`);
    console.log(`doFraudCheck = ${this.doFraudCheckValue}`);

    // cardUrl: object that stores card type code (received from BlueSnap) and associated card image URL
    var cardUrl = {
      "AMEX": "https://files.readme.io/97e7acc-Amex.png", 
      "DINERS": "https://files.readme.io/8c73810-Diners_Club.png",
      "DISCOVER": "https://files.readme.io/caea86d-Discover.png",
      "JCB": "https://files.readme.io/e076aed-JCB.png",
      "MASTERCARD": "https://files.readme.io/5b7b3de-Mastercard.png",
      "VISA": "https://files.readme.io/9018c4f-Visa.png"
    }; 
   

    var bsObj = {
      //insert your Hosted Payment Fields token
      token: bluesnapToken, 
      onFieldEventHandler: {
        /*OPTIONAL*/ setupComplete: function () {
          console.log("setupComplete");
        },
        /*OPTIONAL*/ threeDsChallengeExecuted: function () {
          console.log("threeDsChallengeExecuted");
        },
        // tagId returns: "ccn", "cvv", "exp"
        onFocus: function(tagId) {
          // Handle focus
          this.changeImpactedElement(tagId, "hosted-field-valid hosted-field-invalid", "hosted-field-focus");
        }.bind(this),
        onBlur: function(tagId) {
          // Handle blur
          this.changeImpactedElement(tagId, "hosted-field-focus");
        }.bind(this),
        onError: function(tagId, errorCode, errorDescription) {
          console.log(`errorCode = ${errorCode}, errorDescription = ${errorDescription}`);
          // Handle a change in validation
          this.changeImpactedElement(tagId, "hosted-field-valid hosted-field-focus", "hosted-field-invalid");
          if ($("#" + tagId + "-help").is('*')) {
            $("#" + tagId + "-help").removeClass('helper-text-green').text(errorDescription);
          } else {
            toastr.error(errorDescription);
          }
        }.bind(this),
        onType: function(tagId, cardType, cardData) {
          // get card type from cardType and display card image
          $("#card-logo > img").attr("src", cardUrl[cardType]);
          if (null != cardData) {
            // $("#" + tagId + "-help").addClass('helper-text-green').text(JSON.stringify(cardData));
          }
        }.bind(this),
        onValid: function(tagId) {
          // Handle a change in validation
          this.changeImpactedElement(tagId, "hosted-field-focus hosted-field-invalid", "hosted-field-valid");
          $("#" + tagId + "-help").text("");
        }.bind(this)
      },
      //styling is optional
      style: {
        // Styling all inputs
        "input": {
          "font-size": "14px",
          "font-family": "Helvetica Neue,Helvetica,Arial,sans-serif",
          "line-height": "1.42857143",
          "color": "#555"
        },

        // Styling a specific field
        /*"#ccn": {
                          
                      },*/

        // Styling Hosted Payment Field input state
        ":focus": {
          "color": "#555"
        }
      },
      ccnPlaceHolder: "4111 2222 3333 4444",
      cvvPlaceHolder: "123",
      expPlaceHolder: "MM / YY"
    } // end of bsObj

    if (this.doFraudCheckValue) {
      bsObj['3DS'] = this.doFraudCheckValue; // true
    }

    setTimeout(function() {
      if (this.paymentMethodValue == 'new_card') {
        bluesnap.hostedPaymentFieldsCreate(bsObj);        
      } else if (this.paymentMethodValue == 'existing_cards') {
        this.setupThreeDsForExistingCards();
      }
    }.bind(this), 200);

    $('input[type=submit][name=commit]').off('click');
    $('input[type=submit][name=commit]').click(function(){
      this.reset_error_messages();
      var formIsValid = true;

      if ($('#zevents_event_order_preferred_language').find(":selected").val() === '') {
        $('div.prefered-lang-form-group').addClass('invalid-form-group');
        $('#zevents_event_order_preferred_language').addClass('is-invalid');
        $('div.prefered-lang-form-group').append('<div class="invalid-feedback">Prefered language cannot be blank</div>')

        formIsValid = false
      }

      if ($('#zevents_event_order_external_agent_id').val() === '') {
        $('div.zevents_event_order_external_agent_id').addClass('invalid-form-group');
        $('input#zevents_event_order_external_agent_id').addClass('is-invalid');
        $('div.zevents_event_order_external_agent_id').append('<div class="invalid-feedback">Agent ID cannot be blank</div>')

        formIsValid = false
      }

      const pollDropdowns = document.querySelectorAll('[id^="poll_answer_"]');
      pollDropdowns.forEach((pollDropdown) => {
        if (pollDropdown && (pollDropdown.dataset.required == 'true')) {
          if (pollDropdown.value == '') {
            const $pollDDdiv = $(pollDropdown).closest('div.form-group');
            $pollDDdiv.addClass('invalid-form-group');
            $pollDDdiv.find('.form-control').addClass('is-invalid');
            $pollDDdiv.append(`<div class="invalid-feedback">${i18n.t('field_cannot_be_blank')}</div>`)

            formIsValid = false;
          }
        }
      })

      const specialDropdown1 = document.querySelector('#extra_dropdown1_with_configurable_values');
      if (specialDropdown1 && (specialDropdown1.dataset.required == 'true')) {
        if (specialDropdown1.value == '') {
          const $specialDDdiv = $('div.form-group#extra_dropdown_with_configurable_values')
          $specialDDdiv.addClass('invalid-form-group');
          $specialDDdiv.find('.form-control').addClass('is-invalid');
          $specialDDdiv.append(`<div class="invalid-feedback">${i18n.t('field_cannot_be_blank')}</div>`)

          formIsValid = false;
        }
      }

      const toc_checkbox = document.querySelector('#toc_checkbox');
      if (toc_checkbox) {
        if (!toc_checkbox.checked) {
          const chkboxContainer = toc_checkbox.closest('.form-check');
          $(chkboxContainer).addClass('invalid-form-group');
          $(toc_checkbox).addClass('is-invalid');
          $(toc_checkbox).append('<div class="invalid-feedback">Please accept the Terms and Conditions before proceeding</div>');
          formIsValid = false;
        }
      }

      if (formIsValid) {
        if($("[name='credit_card_option']:checked").val()=="new_card" && $('.credit-card-section').is(":visible")) { // New Card
          this.doWhenClickingSubmitBtn();
          return false;
        } else { // Existing Card
          if (this.doFraudCheckValue) { // Existing Card with 3DS
            const selectedCard = $('[name="zevents_event_order[credit_card_id]"]:checked');
            var previouslyUsedCard = {
              "last4Digits": selectedCard.data('last-4-digits').toString(),
              "ccType": selectedCard.data('cc-type'),
              "amount": this.amountValue,
              "currency": this.currencyValue
            }
            console.log(`previouslyUsedCard = ${JSON.stringify(previouslyUsedCard)}`);
            bluesnap.threeDsPaymentsSubmitData(previouslyUsedCard);
            return false;
          }
        }
      } else {
        toastr.error('Please enter required fields');
        return false
      }
    }.bind(this));

    const pollDropdowns = document.querySelectorAll('[id^="poll_answer_"]');
    pollDropdowns.forEach((pollDropdown) => {
      if (pollDropdown && (pollDropdown.dataset.required == 'true')) {
        $(pollDropdown).on('change', () => {
          if ($(pollDropdown).val() != '') {
            this.reset_error_message_for_poll_dropdown(pollDropdown);
          }
        });
      }
    })

    const specialDropdown1 = document.querySelector('#extra_dropdown1_with_configurable_values');
    if (specialDropdown1) {
      $(specialDropdown1).on('change', function() {
        if ($(specialDropdown1).val() != '') {
          this.reset_error_message_for_special_dropdown1();
        }
      }.bind(this));
    }

    const toc_checkbox = document.querySelector('#toc_checkbox');
    if (toc_checkbox) {
      $('#toc_checkbox').on('change', function() {
        if (document.querySelector('#toc_checkbox').checked) {
          this.reset_error_message_for_toc_checkbox();
        }
      }.bind(this));
    }

    // Clear out 3ds fields when page loads
    $("#zevents_event_order_credit_card_attributes_threedsecure_reference_id").val('');
    $("#zevents_event_order_credit_card_attributes_threedsecure_auth_result").val('');
  } // end of connect

  disconnect() {
    console.log("disconnecting from bluesnap controller");
  }

  reset_error_messages(){
    $('.invalid-feedback').remove();
    $('div.zevents_event_order_external_agent_id').removeClass('invalid-form-group');
    $('input#zevents_event_order_external_agent_id').removeClass('is-invalid');
    $('div.prefered-lang-form-group').removeClass('invalid-form-group');
    $('#zevents_event_order_preferred_language').removeClass('is-invalid');

    this.reset_error_message_for_special_dropdown1();

    const pollDropdowns = document.querySelectorAll('[id^="poll_answer_"]');
    pollDropdowns.forEach((pollDropdown) => {
      this.reset_error_message_for_poll_dropdown(pollDropdown);
    })
  }

  reset_error_message_for_special_dropdown1() {
    // Special dropdown1
    const $specialDDdiv = $('div.form-group#extra_dropdown_with_configurable_values')
    $specialDDdiv.removeClass('invalid-form-group');
    $specialDDdiv.find('.form-control').removeClass('is-invalid');
  }

  reset_error_message_for_toc_checkbox() {
    const toc_checkbox = document.querySelector('#toc_checkbox');
    if (toc_checkbox) {
      const chkboxContainer = toc_checkbox.closest('.form-check');
      $(chkboxContainer).removeClass('invalid-form-group');
      $(toc_checkbox).removeClass('is-invalid');
    }
  }

  reset_error_message_for_poll_dropdown(ele) {
    const $pollDDdiv = $(ele).closest('div.form-group');
    $pollDDdiv.removeClass('invalid-form-group');
    $pollDDdiv.find('.form-control').removeClass('is-invalid');
  }

  setStates(country_code) {
    var select_wrapper = $("#zevents_credit_card_state");
    var country_code = $("#zevents_event_order_credit_card_attributes_country").val();
    console.log(country_code);
    $('select', select_wrapper).attr('disabled', true);
    var url =  $("#subregions_path").val(); 
    var url = url +
              "?" +
              $.param({
                parent_region: country_code,
                model_name: "credit_card"
              });
    select_wrapper.load(url);
  };
  
  /* Calling bluesnap.submitCredentials: function that submits card data to 
  BlueSnap and calls input function with card data object if submission was successful */
  doWhenClickingSubmitBtn() {
    const callbackFunction = (callback) => {
      console.log("in bluesnap callback: callback = ", JSON.stringify(callback));
      if (null != callback.error) {
        var errorArray = callback.error;
        errorArray.forEach(error =>  {
          // If we get 3DS error, let's show an alert and reload the page. For other errors, let's show them inline.
          if ((error.tagId == "ccn") && (error.errorCode == "14101")) {
            bootbox.alert(error.errorDescription, () => Turbo.visit(window.location.href));
          } else {
            $("#" + error.tagId + "-help").text(error.errorDescription);
          }
        });
        return false;
      } else if (null != callback.cardData) {
        var cardData = callback.cardData;
        // console.log("in bluesnap callback: cardData = ", JSON.stringify(cardData));
        if (!this.doFraudCheckValue) {
          this.saveBluesnapCreditCard(cardData);
        }
        else if (this.doFraudCheckValue && ['AUTHENTICATION_BYPASSED', 'AUTHENTICATION_SUCCEEDED', 'AUTHENTICATION_UNAVAILABLE'].includes(callback.threeDSecure.authResult)) {
          this.saveBluesnapCreditCard(cardData, callback.threeDSecure);
        } else {
          bootbox.alert(i18n.t("bluesnap.authentication_error"), () => Turbo.visit(window.location.href));
          return false;
        }
      } else {
        bootbox.alert(i18n.t("bluesnap.error"), () => Turbo.visit(window.location.href));
        return false;
      }
    } // end of callbackFunction code
     
    if (this.doFraudCheckValue) {
      console.log('submitting to bluesnap with 3DS - ', { amount: this.amountValue, currency: this.currencyValue });
      bluesnap.hostedPaymentFieldsSubmitData(callbackFunction, { amount: this.amountValue, currency: this.currencyValue });
    } else {
      console.log('submitting to bluesnap without 3DS');
      bluesnap.hostedPaymentFieldsSubmitData(callbackFunction);
    }
  }

  saveBluesnapCreditCard(card_data, threeDSecure) {
    $("#zevents_event_order_credit_card_attributes_card_number").val(card_data.last4Digits);
    $("#zevents_event_order_credit_card_attributes_expiry_date").val(card_data.exp);
    $("#zevents_event_order_credit_card_attributes_cc_type").val((card_data.ccType));
    if (threeDSecure) {
      $("#zevents_event_order_credit_card_attributes_threedsecure_reference_id").val(threeDSecure.threeDSecureReferenceId);
      $("#zevents_event_order_credit_card_attributes_threedsecure_auth_result").val(threeDSecure.authResult);
    }
    $(".edit_zevents_event_order").submit();
  }

  changeImpactedElement(tagId, removeClass, addClass) {
    removeClass = removeClass || "";
    addClass = addClass || "";
    $("[data-bluesnap=" + tagId + "]")
        .removeClass(removeClass)
        .addClass(addClass);
  }

  setupThreeDsForExistingCards() {
    if (this.doFraudCheckValue) { // If 3DS is enabled
      bluesnap.threeDsPaymentsSetup(this.bluesnapTokenValue, (sdkResponse) => {
        var code = sdkResponse.code;
        if (code == 1) {  // on Success
          var cardData = sdkResponse.cardData;
          var threeDSecure = sdkResponse.threeDSecure;
          console.log('sdkResponse (cardData and threeDSecure) = ', sdkResponse);
          if (threeDSecure) {
            $("#zevents_event_order_credit_card_attributes_threedsecure_reference_id").val(threeDSecure.threeDSecureReferenceId);
            $("#zevents_event_order_credit_card_attributes_threedsecure_auth_result").val(threeDSecure.authResult);
          }
          // Submit the form
          $('input[type=submit][name=commit]').closest('form').submit();
        } else {
          // in case some errors occurred 
          var errorsArray = sdkResponse.info.errors;
          // in case some warnings occurred
          // var warningsArray = sdkResponse.info.warnings;
          const combinedErrorMsg = errorsArray.reduce((error, msg) => msg + error.errorDescription);
          bootbox.alert(combinedErrorMsg, () => Turbo.visit(window.location.href));
          return false;
        }
      });
    }
  }
}