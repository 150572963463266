import consumer from "./consumer"

consumer.subscriptions.create("WebNotificationsChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
    console.log('connected to web notifications channel');
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    console.log(data);
    if (data.type == 'read_notification') {
      if (data.action == 'remove_mark') {
        $('#unread-notification-mark').addClass('d-none');

        // Dispatch an event so that other listeners can do something (like Visible Web Alerts)
        const removeMarkForBellIconEvent = new CustomEvent('remove-mark-for-bell-icon');
        window.dispatchEvent(removeMarkForBellIconEvent);
      } else {
        $('#unread-notification-mark').removeClass('d-none');
      }
    } else {
      $('.day-notifications-container span.no_notifications_msg').addClass('d-none');
      $('.day-notifications[data-day=' + data.created_date + ']').removeClass('d-none');
      $('.day-notifications[data-day=' + data.created_date + ']').siblings('h4').removeClass('d-none');
      $('.day-notifications[data-day=' + data.created_date + ']').prepend(data.html);

      // Dispatch an event so that other listeners can do something (like Visible Web Alerts)
      const newWebAlertEvent = new CustomEvent('new-web-alert', { detail: { html: data.html } });
      window.dispatchEvent(newWebAlertEvent);
    }
  }
});
