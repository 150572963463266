/* Dev Notes:
There is a <turbo-frame id="modal"></turbo-frame> tag in application.html.erb
We can make that turbo-frame as the target/reciever of any modal popup.
The only thing we have to do is - give the same id (i.e. "modal") to our turbo-frame.
E.g. See polls/_form.html.erb
The flow is as follows:
1) user clicks on a link whose data-turbo-frame="modal"
2) the content recieved from link's href is put inside <turbo-frame id="modal"></turbo-frame>
   (which already exists on the webpage as it is part of application.html.erb)
3) when that happens connect() code runs, where we show the Modal Popup
*/
import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    $(this.element).modal('hide'); // Close modal if already open
    $(this.element).modal('show');
    window.addEventListener('close-global-modal', () => this.close());
    const modalHasLoadedEvent = new CustomEvent('modal-has-loaded');
    window.dispatchEvent(modalHasLoadedEvent);
  }

  close() {
    $(this.element).modal('hide');
    this.element.remove();
    if (this.$turboFrame) this.$turboFrame.empty().removeAttr('src');
  }

  open(event) {
    const link_src = event.target.closest("a").href;
    const modal_src = $turboFrame ? this.$turboFrame.attr('src') : null;

    if (link_src == modal_src) {
      event.preventDefault();
      $(this.element).modal('show');
    }
  }

  escClose(event) {
    if (event.key === 'Escape') this.close();
  }

  isClickOutside(event) {
    if (event.target.closest('.modal-dialog')) return;
    this.close();
  }

  get $turboFrame() {
    const tf = $(this.element).closest("turbo-frame");
    return (tf.is('*') ? tf : null);
  }
}
