import locales from "../packs/locales"

export default {
  t(keys) {
    const current = document.querySelector("body").getAttribute("data-locale")
    try {
      return keys.split('.').reduce((translation_hash, value) => translation_hash[value], locales[current])
    }
    catch {
      // return default value if no translation value
      return keys.split('.').reduce((translation_hash, value) => translation_hash[value], locales['default'])
    }
  }
}