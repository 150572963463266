// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("@hotwired/turbo-rails")
require("channels")
require("controllers")
import { get, post, put, patch, destroy } from '@rails/request.js'
import "bootstrap";

import "@fortawesome/fontawesome-free/js/all";
import "../stylesheets/stripe";
import "../stylesheets/v2_application";

import { Turbo } from "@hotwired/turbo-rails";
window.Turbo = Turbo

const components = require.context("../src", true);
components.keys().forEach(components);

import 'bootstrap-select/js/bootstrap-select.js';

import { BrowserQRCodeReader } from '@zxing/library';

import 'bootstrap4-tagsinput/tagsinput.js';

import 'jquery_nested_form';

require.context('../images', true)

import flatpickr from "flatpickr"
window.bootbox = require('bootbox');

require ("flatpickr/dist/flatpickr.css")

global.jQuery, global.$ = require("jquery");
require('./jquery.flexslider-min');

import toastr from 'toastr';
global.toastr = toastr;


import Tagify from '@yaireo/tagify';
global.Tagify = Tagify;

require('vendor/bluesnap.js');
require('vendor/jitsi_external_api.js');

window.feather_icons = require('feather-icons');

var jstz = require('jstimezonedetect');

// fullcalendar
import * as Calendar from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';

window.FullCalendar = Calendar;
window.dayGridPlugin = dayGridPlugin;
window.timeGridPlugin = timeGridPlugin;
window.listPlugin = listPlugin;
window.interactionPlugin = interactionPlugin;

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

$(function() {
  $('[data-toggle="tooltip"]').tooltip();
});

$(function() {
  $('[data-toggle="popover"]').popover();
});
$(function() {
  $(".dropdown-toggle").dropdown();
});

var Trix = require("trix")
require("@rails/actiontext")

Trix.config.textAttributes.textColor = {
    styleProperty: "color",
    inheritable: 1
};

// MOVED: image_controller.js
(function ($) {
  $.fn.resizeImage = function() {
    console.log("$(e).resizeImage() DEPRECATED");
  }

  $.fn.isInViewport = function() {
    var elementTop = $(this).offset().top;
    var elementBottom = elementTop + $(this).outerHeight();
    var viewportTop = $(window).scrollTop();
    var viewportBottom = viewportTop + $(window).height();
    return elementBottom > viewportTop && elementTop < viewportBottom;
  };
}(jQuery));

/* insert the button visual in the default toolbar */
addEventListener("trix-initialize", function(event) {
  var buttonHTML = '';
  var icon = '<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-tag-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">      <path fill-rule="evenodd" d="M2 1a1 1 0 0 0-1 1v4.586a1 1 0 0 0 .293.707l7 7a1 1 0 0 0 1.414 0l4.586-4.586a1 1 0 0 0 0-1.414l-7-7A1 1 0 0 0 6.586 1H2zm4 3.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>    </svg>';

  if (event.target.id == "zevents_event_description") {
    buttonHTML = '<button type="button" id="event_description_text_color" > '+icon+ '</button>';
  } else if (event.target.id == "zevents_event_disclaimer") {
    buttonHTML = '<button type="button" id="event_disclaimer_text_color" >'+icon+ '</button>';
  } else if (event.target.id == "zevents_event_schedule") {
    buttonHTML = '<button type="button" id="event_schedule_text_color" >'+icon+ '</button>';
  } else if (event.target.id == "zevents_trainer_profile_bio")   {
    buttonHTML = '<button type="button" id="trainer_profile_bio_text_color" >'+icon+ '</button>';
  } else{
    buttonHTML = '<button type="button" id="trainer_profile_style_text_color" >'+icon+ '</button>';
  }

  event.target.toolbarElement.querySelector(".trix-button-group").insertAdjacentHTML("beforeend", buttonHTML);
});

// (function() {
//   if ($) {
//     var token = $( 'meta[name="csrf-token"]' ).attr( 'content' );

//     $.ajaxSetup( {
//       beforeSend: function ( xhr ) {
//         xhr.setRequestHeader( 'X-CSRF-Token', token );
//       }
//     });
//   }
// })();


$(function(){
  var $activeClass = $(".admin-events-tabs #myTab a").click(function(){
    $activeClass.removeClass('active');
    $(this).addClass('active');
  });
});

$(document).ready(function (){
  $(function(){
   var $moreDivHeight= $(".existing-cards-wrapper").innerHeight();
    if($moreDivHeight > 230){
      $(".read-more").css({"display":"block"});
      }
  });
   $(".read-more").click(function(){
    $(".existing-cards-wrapper").css({"height":"auto"});
   });

   // $('#accordion .panel-heading').click(function(){
   //  $('.panel-collapse').slideToggle();
   //$('.panel-collapse').css({"display":"block"});
   // });

   var profileNavbar = $(".profiles_show .navbar-collapse");
    profileNavbar.on("click", "a", null, function () {
    profileNavbar.collapse('hide');
   });

   function scrollDown() {
    $('html, body').animate({
        scrollTop: $(window).scrollTop() - 100
    }, 400)
   }

  $('.profile-header #navbarNav li a').click(function(event){
    if (this.hash !== "") {
      event.preventDefault();
      var hash = this.hash;
      $('html, body').animate({
        scrollTop: $(hash).offset().top
      }, 800, function(){
        window.location.hash = hash;
        scrollDown();
      });
    }

    $(this).parents('.navbar-nav').children('li').each(function(){
      $(this).removeClass('active')
    })
    $(this).closest('li').addClass('active');
  })

  
});

$(document).on("turbo:load", function() {
  var timezone = jstz.determine().name();
  document.cookie = 'timezone='+timezone+"; path=/";

  if (localStorage.getItem('cookies_disclaimer') == undefined) {
    $('.cookies-disclaimer').removeClass('d-none');        
  }
  $('.disclaimer-close').click(function(){
    localStorage.setItem('cookies_disclaimer', true);
  });

  feather_icons.replace();
  $('.card-image, .placeholder-image').each(function(){    
     var elem = $(this).children('a').length > 0 ? $(this).children('a') : $(this);
     setTimeout(() => {  
       elem.height(($(this).outerWidth() / 16) * 9); 
     }, 1000);    
   });
});

////////////////////////////////////////////////////////////////////////////////////////////
// Spinner related code - begin
//
window.show_spinner = () => $('#mySpinner').show();
window.hide_spinner = () => setTimeout(() => $('#mySpinner').hide(), 100);

// window.addEventListener('turbo:click', () => window.show_spinner()); // When a link is clicked, show the spinner
window.addEventListener("turbo:load", () => window.hide_spinner());  // When page finishes loading, hide the spinner
window.addEventListener("modal-has-loaded", () => window.hide_spinner());

// When page unloads, show the spinner
window.onunload = () => window.show_spinner();

// Below is for Turbolinks
document.addEventListener("turbo:request-start", () => window.show_spinner());
document.addEventListener("turbo:request-end",   () => window.hide_spinner());
document.addEventListener("turbo:submit-end",    () => window.hide_spinner());

// Below is for ajax calls fired using $.ajax
$(document).ajaxStart(() => window.show_spinner());
$(document).ajaxStop(()  => window.hide_spinner());

// Below is for Forms submitted by rails-ujs via ajax (via data-remote='true')
$(document).on("turbo:load", function() {
  window.addAjaxLoaderHandler();
});

$(document).on('turbo:click', function(event) {
  if (event.target.dataset.turboFrame == 'modal') {
    var link_src = event.detail.url;
    var modal_src = $('#modal').attr('src');

    if (link_src == modal_src) {
      event.preventDefault();
      $('#turbo_modal').modal('show');
    }
  }
});

window.addAjaxLoaderHandler = function() {
  $(document).on('ajax:send', (event) => {
    // https://guides.rubyonrails.org/working_with_javascript_in_rails.html#rails-ujs-event-handlers
    // https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/onreadystatechange
    // const xhr = event.detail[0];
    // xhr.onreadystatechange = function() {
    //   if (xhr.readyState === XMLHttpRequest.DONE) {
    //     window.hide_spinner();
    //   }
    // };

    window.show_spinner();
    setTimeout(() => window.hide_spinner(), 1000);
  });

  // $(document).on('ajax:complete', (event) => {
  //   window.hide_spinner();
  // });

  // $(document).ajaxSend(function(event, xhr, settings) {
  //   window.show_spinner();
  // });

  // $(document).ajaxComplete(function(event, xhr, settings) {
  //   window.hide_spinner();
  // });
}

// Below is for normal form submissions
$(document).on("turbo:load", function() {
  
  $('form').on('submit',function(e) {
    window.show_spinner();
  });
});

//
// Spinner related code - end
////////////////////////////////////////////////////////////////////////////////////////////
   
var is_ios_device = /iPhone|iPad|iPod/i.test(navigator.platform);
var is_safari = navigator.userAgent.toLowerCase().indexOf('safari/') > -1; 
window.ios_native_app = (is_ios_device && !is_safari)&& true;

window.livestreamEndedConfirmBox = function(eventId) {
  $('#livestream_ended').modal('show');
  $('#livestream_ended').on('hidden.bs.modal', function () {
    Turbo.visit("/v2");
  });
}

window.stopStream = async function(eventId) {
  const response = await post(`/v2/events/${eventId}/stop_stream`, {responseKind: 'json'})
  if(response.ok) {
    const body = await response.json
    Turbo.visit(body.url)
  }
}

window.leaveStream = async function(eventId) {
  // rails request.js is following the redirect but not replacing the content. so using json type
  // and doing that manually.
  const response = await post(`/v2/events/${eventId}/leave_stream`, {responseKind: 'json'})
  if(response.ok) {
    const body = await response.json
    Turbo.visit(body.url)
  }
}

// We want to instantiate VisibleWebAlertsController only once, because we are putting the Visible Web Alerts
// in a Queue (that is persisted to sessionStorage), and we need items to be pulled from the Queue one at a time.
// So we are doing it on turbo:load and debouncing it over 1 second to prevent double-loading.
// If we attach it to a Div (like we normally would), the controller's connect() is firing twice,
// possibly because 'cached version of the page' is loaded first, and then actual content is
// loaded. https://blog.corsego.com/double-loading-stimulus-controllers
document.addEventListener("turbo:load", () => {
  if (window.visibleWebAlertsDebouncerValue) window.clearTimeout(window.visibleWebAlertsDebouncerValue);

  window.visibleWebAlertsDebouncerValue = window.setTimeout(() => {
    const ele = document.querySelector('#visible_web_alert');
    if (ele) {
      ele.dataset.visibleWebAlertsQueueValue = sessionStorage.getItem('visibleWebAlerts') || '[]';
      ele.dataset.controller = 'visible-web-alerts';
    }
  }, 1000);

});