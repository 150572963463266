import { Controller } from "stimulus"

export default class extends Controller {

  static values = {
    jwt: String,
    eventId: String,
    roomName: String,
    isHost: String
  }

  connect() {
    this.initIframeAPI();
  }

  initIframeAPI() {
    let api;
    console.log('initializing jitsi frame');
    const domain = '8x8.vc';
    const eventId = this.eventIdValue;
    const isHost = this.isHostValue;
    const options = {
      roomName: this.roomNameValue,
      jwt: this.jwtValue,
      width: 700,
      height: 700,
      parentNode: document.querySelector('#meet'),
      interfaceConfigOverwrite: {
        AUTO_PIN_LATEST_SCREEN_SHARE: 'remote-only'
      },
      configOverwrite: {
        // disable the prejoin page
        prejoinPageEnabled: false,
        //optionally we can control the mute state on join from the emebedding application
        startWithAudioMuted: true,
        startWithVideoMuted: true,
        // If true, any checks to handoff to another application will be prevented
        // and instead the app will continue to display in the current browser.
        disableDeepLinking: true,
        // toolbarButtons: ['hangup', 'microphone', 'camera']
        toolbarConfig: {
          alwaysVisible: true,
        },
        toolbarButtons: [
          'camera',
          'chat',
          'closedcaptions',
          'desktop',
          'download',
          'embedmeeting',
          'etherpad',
          'feedback',
          'filmstrip',
          'fullscreen',
          'hangup',
          'help',
          // 'invite',
          'livestreaming',
          'microphone',
          'mute-everyone',
          'mute-video-everyone',
          // 'participants-pane',
          'profile',
          'raisehand',
          'recording',
          'security',
          'select-background',
          'settings',
          // 'shareaudio',
          // 'sharedvideo',
          'shortcuts',
          'stats',
          'tileview',
          'toggle-camera',
          'videoquality',
          '__end'
        ]
      }
    };
    api = new JitsiMeetExternalAPI(domain, options);
    api.addEventListener(`videoConferenceJoined`, () => {
      const listener = ({ enabled }) => {
        api.removeEventListener(`tileViewChanged`, listener);

        if (!enabled) {
          api.executeCommand(`toggleTileView`);
        }
      };

      api.addEventListener(`tileViewChanged`, listener);
      api.executeCommand(`toggleTileView`);
    });
    api.addListener('readyToClose', () => {
      console.log('meeting ended');
      // if its a host, call stop_stream
      if(isHost) {
        stopStream(eventId)
      } else {
        leaveStream(eventId)
      }
    });
  }
}