import { Controller } from 'stimulus'; 
import i18n  from "../packs/i18n"

export default class extends Controller {
  static values = {
    profilePic: String,
    profileBg: String
  }

  handleFileChange(event){    
    var input = $(event.currentTarget);
    const allowedExtensions = ['png','gif','jpg','jpeg','bmp'];
    var type = $(input).data('type');
    if(type == 'avatar'){
      var preview = $('.profile-picture img')
      var header_preview = $('.header-profile-picture img')
    }else{
      var preview = $('.profile-background img')
    }
    var file = input[0].files[0];
    const { name:fileName } = file;
    const fileExtension = fileName.split(".").pop();
    if(allowedExtensions.includes(fileExtension)){

      var promise = new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
     
      promise.then((res) => {
        preview.attr("src", res);
        if(header_preview) {
          header_preview.attr("src", res);
        }
        Turbo.navigator.submitForm(document.getElementsByClassName('edit_zevents_profile')[0]);
      });    
   
    }else{
      bootbox.alert(i18n.t('invalid_file_msg'));
    }
  }

  delete_profile_bg(){
    $('#background-image-preview').prop("src", this.profileBgValue)
  }

  delete_profile_pic(){
    $('#profile-image-preview').prop("src", this.profilePicValue)
  }

  clearText(e){
    var Target = e.currentTarget;
    $(Target).parents('.clear-text').find('input[type="text"],input[type="tel"]').val('');
  }

}


