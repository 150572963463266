import { Controller } from 'stimulus'; 
export default class extends Controller {

  reset_form(){
    window.hide_spinner(); 
    this.element.reset();  
    $("#send_button").attr("disabled",false);
  }

}


