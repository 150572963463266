import { Controller } from 'stimulus';
import intlTelInput from 'intl-tel-input';

export default class extends Controller {

  // TO DO: change target names to camelCase
  static targets = []

  connect() {
    console.log("registration controller connected");

    const input = document.querySelector("#v2_user_phone");
    const errorMsg = document.querySelector("#error-msg");
    const form = document.querySelector("#new_v2_user");
    const submitLink = document.querySelector("#v2-create-user-btn");
    const submitButton = document.querySelector("#main-submit");

    if (input) {
      var errorMap = ["Invalid number", "Invalid country code", "Too short", "Too long", "Invalid number"];

      var iti = intlTelInput(input, {
        utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.16/js/utils.js",
        autoFormat: true,
        autoHideDialCode: false,
        excludeCountries: [],
        formatOnDisplay: true,
        initialCountry: 'us',
        separateDialCode: true,
        nationalMode: false,
        customContainer: 'intl-phone-custom-container'
      });

      // on keyup: validate
      input.addEventListener('keyup', function() {
        iti.setNumber(iti.getNumber(intlTelInputUtils.numberFormat.E164));

        if (input.value.trim()) {
          if (iti.isValidNumber()) {
            errorMsg.classList.add("hide");
            document.getElementById("v2_user_full_no").value = iti.getNumber();
          } else {
            var errorCode = iti.getValidationError();
            errorMsg.innerHTML = errorMap[errorCode];
            errorMsg.classList.remove("hide");
            document.getElementById("v2_user_full_no").value = ""
          }
        }
      });

      submitLink.addEventListener("click", function(e) {
        if (input.value.trim()) {
          if (!iti.isValidNumber()) {
            var errorCode = iti.getValidationError();
            errorMsg.innerHTML = errorMap[errorCode];
            errorMsg.classList.remove("hide");
            document.getElementById("v2_user_full_no").value = ""
            return false;
          } else {
            if (form.requestSubmit) {
              if (submitButton) {
                form.requestSubmit(submitButton);
              } else {
                form.requestSubmit();
              }
            } else {
              form.submit();
            }
          }
        } else {
          errorMsg.innerHTML = "Phone can't be blank";
          errorMsg.classList.remove("hide");
          window.hide_spinner();
          return false;
        }
      });
    }
  }
}
