import { Controller } from "stimulus";
import animateCSS from '../animateCSS.js';
import addSwipeEvent from "../packs/basicSwipe.js";

export default class extends Controller {
  static values = {
    queue: Array,
    alreadyShowing: Boolean,
    timeoutVariable: String
  }

  initialize() {
    this.alreadyShowingValue = false;
    window.clearTimeout(this.timeoutVariable);
  }

  connect() {
    console.log('%cVisible Web Alerts: connected - visible_web_alerts_controller', 'color: green; font-weight: bold;background: lightgray; padding: 5px; border-radius: 5px;');

    window.addEventListener('new-web-alert', e => this.enqueue(e.detail.html));
    window.addEventListener('remove-mark-for-bell-icon', e => this.clearQueue());

    document.querySelector('#close_visible_web_alert').addEventListener('click', () => {
      this.hideAlert({ hideBeforeTimeoutFinishes: true, extraDelayForProcessingNextItemOfQueue: false });
      this.sendRequestToServerThatNotificationHasBeenRead();
    })

    addSwipeEvent(this.element, 'swipeUp', () => {
      console.log('%cVisible Web Alerts: swipe up event for Visible Web Alert', 'color: green; font-weight: bold;background: lightgray; padding: 5px; border-radius: 5px;');
      this.hideAlert({ hideBeforeTimeoutFinishes: true, extraDelayForProcessingNextItemOfQueue: false });
      this.sendRequestToServerThatNotificationHasBeenRead();
    })
    
    // If user clicks on a Link (<a>) within a Visible-Web-Alert,
    // we want to close that Visible-Web-Alert and show the next one (with some extra delay).
    // We also want to mark that Notification as Read.
    $(document).on('click', '[data-controller="visible-web-alerts"] a', () => {
      this.hideAlert({ hideBeforeTimeoutFinishes: true, extraDelayForProcessingNextItemOfQueue: true });
      this.sendRequestToServerThatNotificationHasBeenRead();
    })
  }

  disconnect() {
    console.log('%cVisible Web Alerts: disconnecting - visible_web_alerts_controller', 'color: green; font-weight: bold;background: lightgray; padding: 5px; border-radius: 5px;');
  }

  enqueue(item) {
    console.log(`%cVisible Web Alerts: About to enqueu - ${item}`, 'color: green; font-weight: bold;background: lightgray; padding: 5px; border-radius: 5px;');
    this.queueValue = [item, ...this.queueValue];
  }

  dequeue() {
    if (this.isQueueEmpty()) {
      return null;
    } else {
      const headOfQ = this.queueValue[this.queueValue.length - 1];
      console.log(`%cVisible Web Alerts: About to dequeu - ${headOfQ}`, 'color: green; font-weight: bold;background: lightgray; padding: 5px; border-radius: 5px;');
      this.queueValue = this.queueValue.slice(0, -1);
      return headOfQ;
    }
  }

  queueValueChanged(newValue, prevValue) {
    // if (!prevValue || (JSON.stringify(prevValue) == JSON.stringify(newValue))) return;
    console.log(`%cVisible Web Alerts: In queueValueChanged: prevValue = ${prevValue.length} alerts, newValue = ${newValue.length} alerts`, 'color: green; font-weight: bold;background: lightgray; padding: 5px; border-radius: 5px;');

    this.saveQueueToSessionStorage();
    if (!this.isQueueEmpty()) this.showAllAlertsInQueue();
  }

  saveQueueToSessionStorage() {
    sessionStorage.setItem('visibleWebAlerts', JSON.stringify(this.queueValue));
  }

  isQueueEmpty() {
    return (this.queueValue.length == 0);
  }

  showAllAlertsInQueue() {
    if (this.alreadyShowingValue) return;

    console.log("%cVisible Web Alerts: In showAllAlertsInQueue()", 'color: green; font-weight: bold;background: lightgray; padding: 5px; border-radius: 5px;');
    const alertHtml = this.dequeue();
    if (alertHtml) {
      console.log(`About to show - ${alertHtml}`);
      this.showAlert(alertHtml);
      this.alreadyShowingValue = true;
      this.timeoutVariable = setTimeout(() => {
        console.log(`About to hide - ${alertHtml}`);
        this.hideAlert();
      }, this._isTurboNativeApp ? 15000 : 60000); // Let us show Visible Web Alert for 15 secs on Phone
    }                                             // and 1 minute on Desktop
  }

  showAlert(alertHtml) {
    this.element.querySelector('.day-notifications').innerHTML = alertHtml;
    this.element.hidden = false;
    animateCSS(this.element, 'slideInDown');
  }

  async hideAlert(options) {
    console.log(`About to hide Alert`);
    await animateCSS(this.element, 'slideOutUp');
    this.element.hidden = true;
    this.alreadyShowingValue = false;

    if (options && options.hideBeforeTimeoutFinishes) {
      window.clearTimeout(this.timeoutVariable);
    }

    // Dev Notes: In some cases, for e.g. when user clicks on a Link within a Visible-Web-Alert,
    // it might help (for better UX) to have extra delay before showing the next one in the Queue.
    // This is because -
    // 1) Clicking the Link could open a Modal Popup (e.g. a Survey), and in that case,
    //    we want to allow the user to interact with the Modal Popup before showing the next Visible Web Alert.
    // 2) If clicking the Link navigates to a new page, we do not want to Dequeue our Queue just before page redirects.
    const delayProcessing = options && options.extraDelayForProcessingNextItemOfQueue;
    if (delayProcessing) {
      setTimeout(() => this.showAllAlertsInQueue(), 60000);
    } else {
      this.showAllAlertsInQueue(); // Continue on to show any other Alerts in the Queue.
    }
  }

  clearQueue() {
    this.queueValue = [];
  }

  get _isTurboNativeApp() {
    return navigator.userAgent.indexOf("Turbo Native") !== -1
  }

  sendRequestToServerThatNotificationHasBeenRead() {
    const notificationIdDiv = document.querySelector('#visible_web_alert div[data-notification-id]');
    if (notificationIdDiv) {
      $.ajax({
        url: '/v2/notifications/mark_one_as_read',
        method: "POST",
        data: { 'notification_ids': notificationIdDiv.dataset.notificationId }
      });
    }
  }
}
