import { Controller } from 'stimulus'; 
import i18n  from "../packs/i18n"

export default class extends Controller {
  connect() {
    console.log("event-actions connect")
  }

  // unused 2022-09-15
  clone_event(e){
    var event_id = e.target.closest('a').dataset.eventId;
    bootbox.confirm({
      closeButton: false,
      message: i18n.t('close_event'),
      buttons: {
          confirm: {
              label: i18n.t('clone'),
              className: 'btn-primary'
          },
          cancel: {
              label: i18n.t('cancel'),
              className: 'btn-light'
          }
      },
      callback: function (result) {
        if(result){
          var url = '/v2/events/' + event_id + '/clone';
          Turbo.visit(url);
        }
      }
    });
  }

  // app/views/v2/kwivrr/events/_more_actions.html.erb
  deleteEvent(e) {
    var eventId   = e.currentTarget.dataset["eventIdParam"];
    console.log("event-actions delete_event", eventId);
    var eventPath = e.currentTarget.dataset["eventPathParam"];
    var message  = e.currentTarget.dataset["messageParam"];
    var label1  = e.currentTarget.dataset["label1Param"];
    var label2  = e.currentTarget.dataset["label2Param"];

    bootbox.confirm({
      closeButton: false,
      message: message,
      buttons: {
        confirm: {
          label: label1,
          className: 'btn-primary'
        },
        cancel: {
          label: label2,
          className: 'btn-light'
        }
      },
      callback: function(confirmed) {
        if (confirmed) {
          $.post(eventPath, { _method: "delete" });
        }
      }
    }); // bootbox.confirm
  } // delete_event
} // Controller