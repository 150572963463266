import consumer from "../consumer"
$(document).on('turbo:load', function () {
  var eventMessagesSection = document.getElementById("event-messages-section");
  console.log(eventMessagesSection);
  if(eventMessagesSection) {
    // console.log('aaaaaaaaaaa' + $('#event-messages-section').attr('data-event-id'));
    consumer.subscriptions.create(
      {
        channel: "Zevents::EventsChannel",
        event_id: $('#event-messages-section').attr('data-event-id')
      },
      {
        connected() {
          // Called when the subscription is ready for use on the server
          console.log("Connected to the chat  room!");
        },

        disconnected() {
          // Called when the subscription has been terminated by the server
          console.log("disconnected");
        },

        received(data) {   
          console.log("received data = ", JSON.stringify(data));

          if (data.type == "SHOW_POLL") {
            const respond_to_poll_link = document.querySelector('#respond_to_poll_link');
            if (respond_to_poll_link) {
              respond_to_poll_link.href = respond_to_poll_link.dataset.path.replaceAll('__poll_id__', data.poll_id);
              respond_to_poll_link.click();
            }
          } else if (data.type == "HIDE_FROM_USERS_CHAT") {
            $('#message-'+data.message_id).parent().addClass('d-none');
          }else if (data.type == "DISPLAY_USERS_HIDDEN_CHAT") {
            $('#message-'+data.message_id).parent().removeClass('d-none');
          }else if (data.type == "DELETE_FROM_CHAT") {
            $('#message-'+data.message_id).parent().addClass('d-none');
          }else if (data.type == "DISPLAY_HOST_HIDDEN_CHAT") {
            $('#message-'+data.message_id).parent().children().not('div[data-message-id]').css('opacity', 1.0);
          }else if (data.type == 'HOST_HIDE_CHAT'){
            $('#message-'+data.message_id).parent().children().not('div[data-message-id]').css('opacity', 0.4);
          } else {
            var messageTemplate = $('#message-template');
            var content = messageTemplate.children().clone(true, true);
            content.find('[data-role="user-name"]').text(data.user_name);
            content.find('[data-role="message-text"]').text(data.message);
            content.find('[data-role="data-msg-time"]').text("now");
            content.find('[data-role="data-msg-time"]').attr("data-msg-time",data.data_msg_time);
            content.find('[data-role="message_id"]').attr("id",'message-'+data.message_id);
            // Display hide icon only for hosts 
            console.log(  content.find('[data-role="hide_message"]').attr("data-display-hide-icon") )  ;
            if (content.find('[data-role="hide_message"]').attr("data-display-hide-icon") == "true" ) {
              content.find('[data-role="hide_message"]').attr("data-message-id", data.message_id);
              content.find('[data-role="display_message"]').attr("data-message-id", data.message_id);
              content.find('[data-role="hide_message"]').attr("id", "hide_message_"+data.message_id);
              content.find('[data-role="display_message"]').attr("id", "display_message_"+data.message_id);
              content.find('[data-role="hide_message"]').show();  
            }

            // Display delete icon only for users who posted it and event host
            
            if ( content.find('[data-role="delete_message"]').attr("data-user-id") == data.user_id ||  content.find('[data-role="delete_message"]').attr("data-user-id") == data.host_id) {
              content.find('[data-role="delete_message"]').attr("id", "delete_message_"+data.message_id);
              content.find('[data-role="delete_message"]').attr("data-message-id", data.message_id);
              content.find('[data-role="delete_message"]').show();
            }  
            if (data.user_image_url != undefined) {
              var img = document.createElement('img');
              content.find('[data-role="user-image"] img').attr("src",data.user_image_url)
            }
            var element = $('#event-messages-section')
            element.append(content);

            $('#zevents_event_message_message').val('');
          } 
        }
      }
    )
  }
});  



