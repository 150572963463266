import consumer from "../consumer"

$(document).on('turbo:load', function () {
  var eventMessagesSection = document.getElementById("event-messages-section");
  if(eventMessagesSection) {
    consumer.subscriptions.create({
        channel: "Zevents::RoomChannel",
        event_id: $('.event-messages-section').attr('data-event-id'),
        event_room_member_id: $('.event-attendees-section').attr('data-eventroommember-id'),
      },
      {
        connected() {
          // Called when the subscription is ready for use on the server
          console.log("Connected to the chat rddoom!");
        },

        disconnected() {
          // Called when the subscription has been terminated by the server
        },

        received(data) {
          console.log("received data");
          console.log(data);

          if (data.type == "DC_EVENT") {
            livestreamEndedConfirmBox(data.event_id);
            return false;
          } else{        
          
            var element = $('.attendees-item');
            var user_id = data.user_id;

            if (data.type == "UNMUTE_USER") {
              if ( $('#current_user_id').attr("data-current-user-id") == data.user_id) {
                $("#send_button").attr('disabled', false);
              }
            }else if (data.type == "MUTE_USER") {
              if ( $('#current_user_id').attr("data-current-user-id") == data.user_id) {
                $("#send_button").attr('disabled', true);
              }
            }else if (data.type == "BAN_USER") {
              console.log("BAN USER");
              $('#user-'+user_id).parent().remove();
              if ( $('#current_user_id').attr("data-current-user-id") == data.user_id) {
                toastr.error("You are banned from livestream");
                window.location.href = "/v2/events/"; 
              }
            }else if (data.type == "DC_USER") {
              console.log("DC USER");
              $('#user-'+user_id).parent().remove();
              var count = parseInt($("#count").html());
              if (count > 1){
                $("#count").html(count-1); 
              }  
            } else{
              var attendeeTemplate = $('#attendee-template');
              var content = attendeeTemplate.children().clone(true, true);
              content.find('[data-role="user-name"]').text(data.name);
              content.find('[data-role="user_id"]').attr("id",'user-'+user_id);

              if (data.user_image_url != undefined) {
                var img = document.createElement('img');
                img.src = data.user_image_url;
                content.find('[data-role="user-image"]').empty().html(img);
              }

              if ( content.find('[data-role="ban_user"]').attr("data-user-id") == data.event_owner_id) {
                content.find('[data-role="ban_user"]').attr("id", "ban_user_"+data.id);
                content.find('[data-role="ban_user"]').attr("data-member-id", data.id);                
                content.find('[data-role="ban_user"]').show();
              } 

              if ( content.find('[data-role="mute_member"]').attr("data-user-id") == data.event_owner_id) {
                content.find('[data-role="mute_member"]').attr("id", "mute_member_"+data.id);
                content.find('[data-role="member-id"]').val(data.id);
                content.find('[data-role="mute_member"]').show();
              }  

              if ( content.find('[data-role="mute_member"]').attr("data-user-id") == data.event_owner_id) {
                content.find('[data-role="unmute_member"]').attr("id", "unmute_member_"+data.id);
              } 

              var element = $('.event-attendees-section');
              element.append(content);
              $("#count").html(parseInt($("#count").html())+1);

            } 
          }  
       
        }
      }
    );
  }  
});