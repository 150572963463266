import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["back", "next", "skip", "save", "send", "step", "alertType", "step2Container",
    "mediaAttachmentTitle", "mediaAttachmentAbout", "stepSubheading", "destinationDesc",
    "customDestinationUrlContainer", "customDestinationUrl", "futureEvent", 
    "emailMessage", "emailTitle", "pushMessage", "pushTitle", "textMessage",
    "previewButtonLabelDisplay", "previewButtonLabelFormField", 
    "previewButtonLabelDisplayContainer", "previewButtonLabelFormFieldContainer",
    "attachmentDZArea", 'originalAttachment'
  ];
  static values = { currentStep: Number, numOfSteps: Number,
    alertScenario: String, alertType: String, subheadings: Array, 
    title: String, message: String,
    mediaAttachmentTitle: String, mediaAttachmentAbout: String, 
    customDestinationUrl: String, destinationDesc: String, 
    previewButtonLabelFormField: String, previewButtonLabelsDefaults: Array, previewButtonEditMode: Boolean,
    existingAlertsConfigHash: Object, mode: String,
    allAlertTypesHaveAlerts: String, comingSoonFeatureMsg: String,
    attachmentEditMode: Boolean
  };

  connect() {
    document.querySelector('[name="kwivrr_alert_configuration[alert_scenario]"]').addEventListener('change', this.selectAlertScenario.bind(this));

    // console.log("Alert Configurations controller connected");
    // $('#turbo_modal').modal('hide'); // If Modal Popup is open, let's close it.
    feather_icons.replace();

    window.addEventListener('dropzone-upload-started', () => this.disableNextButtonInUploadStep());
    window.addEventListener('dropzone-upload-finished', () => this.enableNextButtonInUploadStep());
    window.addEventListener('dropzone-upload-error', () => this.enableNextButtonInUploadStep());

    // Hide Trix Toolbar for Push & Text (i.e. show it only for Email)
    this.step2ContainerTargets.filter(t => t.dataset.alertType != 'email').forEach(c => {
      const trixToolbar = c.querySelector('trix-toolbar');
      if (trixToolbar) trixToolbar.hidden = true;
    })

    // alert_type, destination_desc, button_label, title, message have UI elements that are different
    // from the actual form fields (that are hidden), so we need to sync them.
    // Dev Notes: This syncing is done in the beginning in connect() and at the end in fillFormAndSubmit().
    // We use the Stimulus 'value' variables for this purpose. 
    // In connect(), we fill Stimulus 'value' variables from Form elements.
    // In fillFormAndSubmit(), we will Form elements with Stimulus 'value' variables.
    if (this.modeValue == 'edit') {
      this.alertScenarioValue = document.querySelector('[name="kwivrr_alert_configuration[alert_scenario]"]').value;
      this.alertTypeValue = document.querySelector('[name="kwivrr_alert_configuration[alert_type]"]').value;
      this.titleValue = document.querySelector('[name="kwivrr_alert_configuration[title]"]').value;
      this.messageValue = document.querySelector('[name="kwivrr_alert_configuration[message]"]').value;
      this.destinationDescValue = document.querySelector('[name="kwivrr_alert_configuration[alert_content_attributes][destination_desc]"]').value;
      this.previewButtonLabelFormFieldValue = document.querySelector('[name="kwivrr_alert_configuration[alert_content_attributes][button_label]"]').value;
    
      // While Editing an Alert Configuration - both Alert Scenario & Alert Type should not be editable
      document.querySelector('[name="kwivrr_alert_configuration[alert_scenario]"]').disabled = true;
      this.alertTypeTargets.forEach(x => x.disabled = true);
    }

    this.showCurrentStep();
  }
  
  initialize() {
    this.currentStepValue = 1;
    this.previewButtonEditModeValue = false;
  }

  disableNextButtonInUploadStep() {
    this.nextTarget.disabled = true;
  }

  enableNextButtonInUploadStep() {
    if (this.mediaAttachmentTitleValue && this.mediaAttachmentAboutValue) {
      this.nextTarget.disabled = false;
    }
  }

  selectAlertScenario(event) {
    this.alertScenarioValue = event.target.value;
  }

  selectAlertType(event) {
    const ele = event.target;
    event.preventDefault();
    this.alertTypeValue = ele.dataset.alertType;
    // console.log(this.alertTypeValue);
  }

  back() {
    // this.currentStepValue = Math.max(this.currentStepValue - 1, 1);
    if (this.currentStepValue == 4) {
      this.currentStepValue -= 2; // We want to skip step 3 for now
    } else {
      this.currentStepValue = Math.max(this.currentStepValue - 1, 1);
    }
  }

  next() {
    // Goto next step
    // this.currentStepValue = Math.min(this.currentStepValue + 1, this.numOfStepsValue);
    if (this.currentStepValue == 2) {
      this.currentStepValue += 2; // We want to skip Step 3 for now
    } else {
      this.currentStepValue = Math.min(this.currentStepValue + 1, this.numOfStepsValue);
    }
  }

  alertTypeValueChanged() {
    let ele = this.alertTypeTargets.find(t => t.dataset.alertType == this.alertTypeValue);
    this.alertTypeTargets.forEach(t => {
      (t == ele) ? t.classList.add('selected') : t.classList.remove('selected');
    })

    // In Step 1 content, we need to enable/disable the Next button
    if (this.currentStepValue == 1) {
      this.nextTarget.disabled = this.alertTypeTargets.find(t => t.classList.contains('selected')) ? false : true;
    }

    // In Step 2 we need to show only appropriate text fields
    //   Example - if Alert-Type is Email, we need to show its fields etc.
    if (this.alertTypeValue) {
      this.step2ContainerTargets.forEach(t => {
        t.hidden = (t.dataset.alertType != this.alertTypeValue);
      })
    }
  }

  alertScenarioValueChanged() {
    if ((this.hasSendTarget && this.hasSaveTarget) && (this.currentStepValue == 5)) {
      this.sendTarget.hidden = (this.alertScenarioValue != 'message');
      this.saveTarget.hidden = !this.sendTarget.hidden;
    }

    // While Creating an Alert Configuration:
    // - We need to enable/disable Alert Types depending on whether an Alert Configuration already exists for Alert-Scenario & Alert-Type combo
    // This is not needed during Edit because both Alert Scenario and Alert Type elements will already have been disabled by the time popup loads
    if (this.modeValue == 'create') {
      this.alertTypeValue = null;
      this.alertTypeTargets.forEach(x => x.disabled = false);
      // Alert Scenario 'message' corresponds to 'Send Now', which should allow any number of alerts to be sent (so multiple Alert Configurations will be there)
      // For other Alert Scenarios, alerts will be sent later, and we need to allow only 1 Alert Configuration for them.
      if (this.alertScenarioValue && (this.alertScenarioValue != "message")) {
        const existingAlertTypes = this.existingAlertsConfigHashValue[this.alertScenarioValue];
        if (existingAlertTypes && (existingAlertTypes.length > 0)) {
          existingAlertTypes.forEach(existingAlertType => {
            const target = this.alertTypeTargets.find(x => x.dataset.alertType == existingAlertType)
            if (target) target.disabled = true;
          })
          if (existingAlertTypes.length == this.alertTypeTargets.length) {
            bootbox.alert(this.allAlertTypesHaveAlertsValue);
          }
        }
      }
    }
  }

  skip() {
    // This should be called only in the 3rd step. If SKIP is clicked, we skip 3 & 4 and goto 5.
    if (this.currentStepValue == 3) {
      this.currentStepValue = 5;
    }
  }

  showCurrentStep() {
    // Show Current Step
    this.stepTargets.forEach((element, index) => {
      element.hidden = !(index+1 == this.currentStepValue);
    })

    // Show correct buttons at the bottom
    const is1stStep = (this.currentStepValue == 1);
    const isLastStep = (this.currentStepValue == this.numOfStepsValue);
    this.backTarget.hidden = is1stStep;                                              // BACK button is hiddden on 1st step
    this.nextTarget.hidden = isLastStep;                                             // NEXT button is hidden on last step
    this.saveTarget.hidden = !isLastStep || (this.alertScenarioValue == 'message');  // SAVE button is hidden on all steps except last
    this.sendTarget.hidden = !isLastStep || (this.alertScenarioValue != 'message');  // SEND button is hidden on all steps except last
    this.skipTarget.hidden = (this.currentStepValue != 3);

    // Show the correct subheading for the step
    this.stepSubheadingTarget.innerHTML = this.subheadingsValue[this.currentStepValue - 1];

    // Enable/Disable Next button
    if (this.currentStepValue == 1) {

      this.nextTarget.disabled = this.alertTypeTargets.find(t => t.classList.contains('selected')) ? false : true;
    }
    if (this.currentStepValue == 2) {
      // this.titleValue = this.emailTitleTarget.value.trim();
      // this.messageValue = this.emailMessageTarget.value.trim();
      this.titleValue = this.getTitleValue();
      this.messageValue = this.getMessageValue();
      this.nextTarget.disabled = !(this.titleValue && this.messageValue);
    }
    if (this.currentStepValue == 4) {
      this.mediaAttachmentTitleValue =  this.mediaAttachmentTitleTarget.value.trim();
      this.mediaAttachmentAboutValue = this.mediaAttachmentAboutTarget.value.trim();
      this.nextTarget.disabled = !(this.mediaAttachmentTitleValue && this.mediaAttachmentAboutValue);
    }
    if (this.currentStepValue == 5) {
      this.sendTarget.disabled = !(this.previewButtonLabelFormFieldValue);
      this.saveTarget.disabled = !(this.previewButtonLabelFormFieldValue);
    }
  }

  currentStepValueChanged() {
    // console.log(`currentStep = ${this.currentStepValue}`);
    this.showCurrentStep();
  }

  selectUploadIn3rdStep() {
    this.next();
  }

  selectRecordIn3rdStep(event) {
    alert(this.comingSoonFeatureMsgValue);
    event.preventDefault();
    event.stopPropagation();
    return false;
  }

  keypressedInStep2(event) {
    this.titleValue = this.getTitleValue();
    this.messageValue = this.getMessageValue();
  }

  getTitleValue() {
    if (this.alertTypeValue == 'email') return this.emailTitleTarget.value.trim();
    if (this.alertTypeValue == 'push') return this.pushTitleTarget.value.trim();
  }

  getMessageValue() {
    if (this.alertTypeValue == 'email') return this.emailMessageTarget.value.trim();
    if (this.alertTypeValue == 'push') return this.pushMessageTarget.value.trim();
    if (this.alertTypeValue == 'text') return this.textMessageTarget.value.trim();
  }

  titleValueChanged() {
    if (this.currentStepValue == 2) {
      this.nextTarget.disabled = (this.alertTypeValue == 'text') ? !this.messageValue : !(this.titleValue && this.messageValue);
    }
  }

  messageValueChanged() {
    if (this.currentStepValue == 2) {
      this.nextTarget.disabled = (this.alertTypeValue == 'text') ? !this.messageValue : !(this.titleValue && this.messageValue);
    }
  }

  keypressedInStep4(event) {
    this.mediaAttachmentTitleValue = this.mediaAttachmentTitleTarget.value.trim();
    this.mediaAttachmentAboutValue = this.mediaAttachmentAboutTarget.value.trim();
    // console.log(`mediaAttachmentTitleValue = ${this.mediaAttachmentTitleValue} mediaAttachmentAboutValue = ${this.mediaAttachmentAboutValue}`);
  }

  mediaAttachmentTitleValueChanged() {
    if (this.currentStepValue == 4) {
      this.nextTarget.disabled = !(this.mediaAttachmentTitleValue && this.mediaAttachmentAboutValue);
    }
  }

  mediaAttachmentAboutValueChanged() {
    if (this.currentStepValue == 4) {
      this.nextTarget.disabled = !(this.mediaAttachmentTitleValue && this.mediaAttachmentAboutValue);
    }
  }

  customUrlKeyPress(event) {
    this.customDestinationUrlValue =  event.target.value.trim();
  }

  customDestinationUrlValueChanged() {
    if (this.currentStepValue == 5) {
      this.sendTarget.disabled = this.customDestinationUrlValue ? false : true;
      this.saveTarget.disabled = this.customDestinationUrlValue ? false : true;
    }
  }

  selectDestinationDesc(event) {
    event.preventDefault();
    const ele = event.target;
    this.destinationDescValue = ele.dataset.destinationDesc;
  }

  destinationDescValueChanged() {
    // Find selected element and store it in 'ele'
    const ele = this.destinationDescTargets.find(t => t.dataset.destinationDesc == this.destinationDescValue);
    if (!ele) return;

    this.destinationDescTargets.forEach(t => {
      (t == ele) ? t.classList.add('selected') : t.classList.remove('selected');
    })

    // If 'Custom' is selected as Destinaton Desc, we need to show the Input textbox
    this.customDestinationUrlContainerTarget.hidden = !(this.destinationDescValue == "custom");
    
    // If 'Go to Future Event' is selected as Destination Desc, we need to show list of upcoming events by host
    if (this.hasFutureEventTarget) {
      this.futureEventTarget.hidden = !(ele.dataset.destinationDesc == "goto_next_event")
    }

    // Change Button Label (that will be shown to user)
    this.previewButtonLabelFormFieldValue = this.previewButtonLabelsDefaultsValue.find(x => x.key == this.destinationDescValue).value;

    // Disable/Enable Send/Save buttons - if Custom, disable if user has not given Custom URL yet; Enable for other cases
    if (this.destinationDescValue == "custom") {
      this.sendTarget.disabled =  this.customDestinationUrlValue ? false : true;
      this.saveTarget.disabled =  this.customDestinationUrlValue ? false : true;
    } else {
      this.sendTarget.disabled = !(this.previewButtonLabelFormFieldValue && this.destinationDescValue);
      this.saveTarget.disabled = !(this.previewButtonLabelFormFieldValue && this.destinationDescValue);
    }
  }

  buttonLabelKeyPress(event) {
    const ele = event.target;
    this.previewButtonLabelFormFieldValue = ele.value;
  }

  previewButtonLabelFormFieldValueChanged() {
    if (!this.hasPreviewButtonLabelDisplayTarget || !this.hasPreviewButtonLabelFormFieldTarget || !this.hasSendTarget || !this.hasSaveTarget) {
      return;
    }
    this.previewButtonLabelDisplayTarget.innerHTML = this.previewButtonLabelFormFieldValue;
    this.previewButtonLabelFormFieldTarget.value = this.previewButtonLabelFormFieldValue;
    this.sendTarget.disabled = !(this.previewButtonLabelFormFieldValue && this.destinationDescValue);
    this.saveTarget.disabled = !(this.previewButtonLabelFormFieldValue && this.destinationDescValue);
  }

  changeToEditMode(event) {
    event.preventDefault();
    this.previewButtonEditModeValue = true;
  }

  backToDisplayMode(event) {
    event.preventDefault();
    this.previewButtonEditModeValue = false;
  }

  previewButtonEditModeValueChanged() {
    if (!this.hasPreviewButtonLabelDisplayContainerTarget) return;
    this.previewButtonLabelDisplayContainerTarget.hidden = this.previewButtonEditModeValue ? true : false;
    this.previewButtonLabelFormFieldContainerTarget.hidden = !this.previewButtonLabelDisplayContainerTarget.hidden;
  }

  changeAttachmentToEditMode(event) {
    event.preventDefault();
    event.stopPropagation();
    this.attachmentEditModeValue = true;
  }

  changeAttachmentToViewMode(event) {
    event.preventDefault();
    event.stopPropagation();
    this.attachmentEditModeValue = false;
  }

  attachmentEditModeValueChanged() {
    if (this.hasAttachmentDZAreaTarget && this.hasOriginalAttachmentTarget) {
      this.attachmentDZAreaTarget.hidden = !this.attachmentEditModeValue;
      this.originalAttachmentTarget.hidden = this.attachmentEditModeValue;
    }
  }

  save() {
    this.fillFormAndSubmit();
  }

  send() {
    this.fillFormAndSubmit();
  }

  fillFormAndSubmit() {
    // The following hidden form fields - 
    // alert_type (alert_configuration), destination_desc (alert_content), button_label (alert_content)
    // need to be filled before form is submitted
    document.querySelector('[name="kwivrr_alert_configuration[alert_type]"]').value = this.alertTypeValue;
    document.querySelector('[name="kwivrr_alert_configuration[title]"]').value = this.titleValue;
    document.querySelector('[name="kwivrr_alert_configuration[message]"]').value = this.messageValue;
    document.querySelector('[name="kwivrr_alert_configuration[alert_content_attributes][destination_desc]"]').value = this.destinationDescValue;
    document.querySelector('[name="kwivrr_alert_configuration[alert_content_attributes][button_label]"]').value = this.previewButtonLabelFormFieldValue;
    
    // Remove form fields of Title & Message that were used only for display purposes 
    // (because we have used diff form fields for displaying Title, Message for each Alert Type)
    // but actual form fields' values that are submitted to the server are hidden ones.
    // If we dont do this, these will be ignored when they reach the server, so they are harmless actually.
    ["email", "push", "text"].forEach(x => {
      const ele = document.querySelector(`.ac-step2 div[data-alert-type=${x}]`)
      if (ele) ele.remove();
    })
    
    const form = document.querySelector('[name="kwivrr_alert_configuration[alert_type]"]').closest('form');
    // form.submit();
    Turbo.navigator.submitForm(form);
  }
  
}