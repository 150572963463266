import { Controller } from 'stimulus';
import Wheel from '../utils/wheel/src/wheel'
import {AlignText} from '../utils/wheel/src/constants.js';
import {loadFonts} from '../utils/wheel/scripts/util';

// Connects to data-controller="wheel"
export default class extends Controller {
  initialize() {
    this.wheel = null;
    this.props = null;
    window.wheelEvent = new Event('WheelEvent');
  }
  connect() {
    $(this.element).on('show.bs.modal', (event) => {
      this.showWheel();
    })
    this.createWheel()
  }

  async showFortuneWheel() {
    $('#fortune_wheel_modal').modal('show');
  }

  spinWheel() {
    window.w.spin(200);
  }

  showWheel() {
    setTimeout(
      function(){
        const el = document.querySelector('.wheel-wrapper')
        el.dispatchEvent(window.wheelEvent);
        $(".wheel-spinner").hide();
      }, 200);
  }
  async createWheel() {
    const imgs = [...document.getElementById('fortune-wheel-imgs').children].map(img => {
      let s = img.src
      s = s.split(/assets/)
      return `/assets/${s[1]}`
    });
    
    this.props = [
      {
        name: 'FortuneWheel',
        lineWidth: 2,
        borderColor: '#fff',
        borderWidth: 4,
        lineColor: '#fff',

        radius: 0.84,
        itemLabelRadius: 0.93,
        itemLabelRadiusMax: 0.35,
        itemLabelRotation: 180,
        itemLabelAlign: AlignText.left,
        itemLabelColors: ['#fff'],
        itemLabelBaselineOffset: -0.07,
        itemLabelFont: 'Amatic SC',
        itemLabelFontSizeMax: 55,
        itemBackgroundColors: ['#7a0000' ],
        image: imgs[0],
        overlayImage: imgs[1],
        items: [
          {
            label: 'FREE',
            weight: 1.0,
            labelColor: '#fff',
          },
          {
            label: '10%',
            weight: 1.0,
            labelColor: '#fff',
          },
          {
            label: '30%',
            weight: 1.0,
            labelColor: '#fff',
          },
          {
            label: '20%',
            weight: 1.0,
            labelColor: '#fff',
          },
          {
            label: '50%',
            weight: 1.0,
            labelColor: '#fff',
          },
          {
            label: '30%',
            weight: 1.0,
            labelColor: '#fff',
          },
          {
            label: '20%',
            weight: 1.0,
            labelColor: '#fff',
          },
          {
            label: '10%',
            weight: 1.0,
            labelColor: '#fff',
          },
        ]
    
      }
    ];
    await loadFonts(this.props.map(i => i.itemLabelFont));
    const elem = document.querySelector('.wheel-wrapper')
    const wheel = new Wheel(elem);
    window.w = wheel;
    elem.addEventListener('WheelEvent', (e) => {
      window.w.init  ({
        ...this.props[0],
        rotationSpeed: window.w.rotationSpeed, // Preserve value so it doesn't get reset.
        rotation: window.w.rotation, // Preserve value so it doesn't get reset.
        onCurrentIndexChange: e => this.currentIndexChange(e),
        onRest: e => this.announcePrice(e),
        onSpin: e => this.clearPrice(e),
      });
    }, false);
  }

  announcePrice(e) {
    const w = this.props[0].items[Number(e.currentIndex)].label
    const el = document.querySelector('.winner-price')
    el.innerHTML = `<h4>You have won a ${w} coupon!</h4>`;
  }

  clearPrice(e) {
    const el = document.querySelector('.winner-price')
    el.innerHTML = '<span></span>';
  }

  currentIndexChange(e) {

  }
}