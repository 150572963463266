import { Controller } from 'stimulus';

export default class extends Controller { 
  static values = {}
  static targets = [
    "speakerPictureUpload",
    "profileImagePreview"
  ]

  connect() {
    console.log('trainerprofile connect');

    // TODO: fix this pattern
    $('select').selectpicker();
  }

  changeSpeakerPictureClick(event) {
    // console.log("changeSpeakerPictureClick()");
    this.speakerPictureUploadTarget.click();
  }

  speakerPictureUploadChange(event) {
    var previewElement = this.profileImagePreviewTarget;
    var file = event.currentTarget.files[0];
    var reader = new FileReader();
    reader.onload = function(e) {
     var image_base64 = e.target.result;
     previewElement.setAttribute('src', e.target.result);      
    };
    reader.readAsDataURL(file);
  }
}