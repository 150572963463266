import { Controller } from 'stimulus';
export default class extends Controller {
  static values = { modal: String }

  connect() {
    this.observer = new MutationObserver(this.renderSomething);
    this.observer.modalStatus = this.modalValue;
    this.observer.observe(this.element, { childList: true });
    feather_icons.replace();
  } // Connect end

  renderSomething(mutationsList, observer) {
    console.log("subtree changed!");
    if (this.modalStatus == 'open') {
      // Do Nothing
    } else {
      $('#turbo_modal').modal('hide');
    }
    $('#turbo_modal').on('shown.bs.modal', function () {
      $('.transferable').css('z-index', 2000)
    })
    $('#turbo_modal').on('hidden.bs.modal', function () {
      $('.transferable').css('z-index', 1)
    })
    feather_icons.replace();
    window.hide_spinner()
  }

  disconnect() {
    console.log("dis-contacted!");
    this.observer.disconnect();
  }

  // get modalStatus() {
  //   fetch(this.modalStatusValue);
  // }
}


