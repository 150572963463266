import { Controller } from 'stimulus';

export default class extends Controller {
	
	initialize() {
		this.window_scroll = $(window).scrollTop();	
		this.background_width = 150;
		this.ccs_top = '100px';		
		this.ccs_opacity = '0';
		this.hfem_top = '-100px';
		this.our_partners_top = '100px';
		this.timer = null;
	}

	connect() {		
		$('.fade-in-this').each(function(i) {
			$(this).addClass('animate-on-load');
		})

		$('footer').hide();
		//$(window).scrollTop(0);		

		window.addEventListener("scroll", this.handleScroll);		  
	}

	disconnect() {
		window.removeEventListener("scroll", this.handleScroll);
	}	

	scroll_up(e) {
		 e.preventDefault();
		 $(window).scrollTop(0);
	}

	handleScroll() {
		let current_scroll_position = $(window).scrollTop();
		let ccs = '-170px';		
		
		if(window.matchMedia("(max-width: 768px)").matches) {
			ccs = '-130px';
		}

		if(window.matchMedia("(max-width: 480px)").matches) {
			this.background_width = 210;
			ccs = '0';
			this.ccs_top = '0';		
			this.ccs_opacity = '1';
			this.hfem_top = '0'
			this.our_partners_top = '0';			
		}

		if(window.matchMedia("(max-width: 1280px)").matches) {
			$('.mobile-view-scroll').fadeIn('fast');
		}
	 	
		if(this.timer !== null) {
			clearTimeout(this.timer);
			this.timer = setTimeout(function() {
				if(window.matchMedia("(max-width: 1280px)").matches) {
					$('.mobile-view-scroll').fadeOut('fast');
				}
			}, 2000)
		}	 	

	 	$('.signup-form-section').css({backgroundSize:(this.background_width + this.window_scroll/50) + '% '+'100%', backgroundPosition:'center '+(this.window_scroll/20) + '%'})

		if(this.window_scroll > current_scroll_position || current_scroll_position == 0) {
			$('.page-header-content, .page-header-bg').fadeIn('fast');			
		} else {
			$('.page-header-content, .page-header-bg').fadeOut('fast');			
		}

		if(current_scroll_position > 3) {
			$('.page-header-bg').css('height', '120px');
		} 

		if(current_scroll_position < 3 && !window.matchMedia("(max-width: 480px)").matches) {						
			$('.page-header-bg').css('height', '30px');			
		}

		if($('.stop-the-pain-section').isInViewport()) {
			$('.create-collect-streamline').stop().animate({'top': `${ccs}`}, 1000);
			$('.stop-the-pain-section h3, .stop-the-pain-section p').animate({'left': '0', 'opacity': '1'}, 1000);	
			$('.create-collect-streamline img, .create-collect-streamline h4, .create-collect-streamline p').delay(500).animate({'left': '0', 'opacity': '1'}, 1000);
			$('.create-collect-streamline hr').delay(500).animate({'width': '100%'}, 1000);			
		}		
		
		if($('.cmea').isInViewport()) {
			$('.cmea').css({'top': this.ccs_top, 'opacity': this.ccs_opacity})
		}
		if($('.cpee').isInViewport()) {
			$('.cpee').css({'top': this.ccs_top, 'opacity': this.ccs_opacity})
		}
		if($('.serp').isInViewport()) {
			$('.serp').css({'top': this.ccs_top, 'opacity': this.ccs_opacity})
		}

		if($('.feel-the-power').isInViewport()) {
			$('.feel-the-power p').animate({'left': '0', 'opacity': '1'}, 1000);
			$('.feel-the-power h3').animate({'top': this.hfem_top, 'opacity': '1'}, 1000);				
		}

		if(window.matchMedia("(max-width: 480px)").matches) {
			if($('.our-partners').isInViewport()) {
				$('.our-partners h3, .our-partners img').animate({'top': this.our_partners_top, 'opacity': '1'}, 1000);				
			}
		} else {
			if($('.our-partners').isInViewport()) {
				$('.our-partners h3, .our-partners img').animate({'left': '0', 'opacity': '1'}, 1000);				
			}
		}

		if($('.our-services-heading').isInViewport()) {			
			$('.our-services-heading').css({backgroundSize:(100 + this.window_scroll/200) + '%', backgroundPosition:'center '+(this.window_scroll/100) + '%'})		
		}

		if($('.our-services-content > ul > li:first-child').isInViewport()) {			
			$('.our-services-content > ul > li:first-child > div:first-child > span').addClass('show-left-box');	
			$('.our-services-content > ul > li:first-child > div:first-child > span hr').addClass('show-border');
			$('.our-services-content > ul > li:first-child > div:last-child > h4').addClass('show-h4');
			$('.our-services-content > ul > li:first-child > div:last-child > ul').addClass('show-ul');
			$('.our-services-content > ul > li:first-child > div:last-child > p').addClass('show-p');
			$('.our-services-content > ul > li:first-child > div:last-child > table').addClass('show-table');
		}

		if($('.our-services-content > ul > li:nth-child(2)').isInViewport()) {			
			$('.our-services-content > ul > li:nth-child(2) > div:first-child > span').addClass('show-left-box');	
			$('.our-services-content > ul > li:nth-child(2) > div:first-child > span hr').addClass('show-border');
			$('.our-services-content > ul > li:nth-child(2) > div:last-child > h4').addClass('show-h4');
			$('.our-services-content > ul > li:nth-child(2) > div:last-child > ul').addClass('show-ul');
			$('.our-services-content > ul > li:nth-child(2) > div:last-child > p').addClass('show-p');
			$('.our-services-content > ul > li:nth-child(2) > div:last-child > table').addClass('show-table');
		}

		if($('.our-services-content > ul > li:nth-child(3)').isInViewport()) {			
			$('.our-services-content > ul > li:nth-child(3) > div:first-child > span').addClass('show-left-box');	
			$('.our-services-content > ul > li:nth-child(3) > div:first-child > span hr').addClass('show-border');
			$('.our-services-content > ul > li:nth-child(3) > div:last-child > h4').addClass('show-h4');
			$('.our-services-content > ul > li:nth-child(3) > div:last-child > ul').addClass('show-ul');
			$('.our-services-content > ul > li:nth-child(3) > div:last-child > p').addClass('show-p');
			$('.our-services-content > ul > li:nth-child(3) > div:last-child > table').addClass('show-table');
		}

		if($('.our-services-content > ul > li:nth-child(4)').isInViewport()) {			
			$('.our-services-content > ul > li:nth-child(4) > div:first-child > span').addClass('show-left-box');	
			$('.our-services-content > ul > li:nth-child(4) > div:first-child > span hr').addClass('show-border');
			$('.our-services-content > ul > li:nth-child(4) > div:last-child > h4').addClass('show-h4');
			$('.our-services-content > ul > li:nth-child(4) > div:last-child > ul').addClass('show-ul');
			$('.our-services-content > ul > li:nth-child(4) > div:last-child > p').addClass('show-p');
			$('.our-services-content > ul > li:nth-child(4) > div:last-child > table').addClass('show-table');
		}

		$('.animate-this').each(function(i) {
			let element_bottom = $(this).position().top + $(this).outerHeight();
      let window_bottom = $(window).scrollTop() + $(window).height();
      if(window_bottom > element_bottom) {
      	$(this).animate({'opacity': '1', }, 1000);
      }
		})
		this.window_scroll = current_scroll_position;		
	}
}