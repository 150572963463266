import { Controller } from 'stimulus'; 
export default class extends Controller {

  static targets = [
    "breakoutWrapper",
    "breakoutTitleChevron",
    "ticket"
  ]

  static values = { index: Number }

  initialize() {
    this.showCurrentTicket();
  }

  connect() {
    feather_icons.replace();

    $('.time_checkbox').click(function() {
      var currentElement = $(this)

      var checked               = currentElement.is(':checked');
      var ticket_id             = currentElement.data('ticketId');
      var breakout_session_id   = currentElement.data('breakoutSessionId');
      var breakout_time_slot_id = currentElement.data('breakoutTimeSlotId');
      var date                  = currentElement.data('date');


      var seatsCountClass     = `time-slot-seats-${breakout_time_slot_id}`
      var breakoutTimeSlotsId = `bs-time-slots-${breakout_session_id}${ticket_id}${date}`

      let prevSeatsCount      = $('.' + seatsCountClass).attr('data-availableseats');
      let currentSeatsCount
      let seatsText

      if (checked) {
        $('#' + breakoutTimeSlotsId + ' input:checked').each(function(index) {
          if (currentElement[0] != $(this)[0]) {
            $(this).prop('checked', false);

            var thisSeatId    = $(this).data('breakoutTimeSlotId');
            var thisSeatClass = `time-slot-seats-${thisSeatId}`;
            var thisSeatCount = $('.' + thisSeatClass).attr('data-availableseats');


            $('.' + thisSeatClass).attr('data-availableseats', (parseInt(thisSeatCount) + 1));
            $('.' + thisSeatClass).text((parseInt(thisSeatCount) + 1) + ' seats left');
          }
        });

        currentSeatsCount = parseInt(prevSeatsCount) - 1

        if (currentSeatsCount == 0) {
          $('.input-time-slots-' + breakout_time_slot_id).each(function(index) {
            if (currentElement[0] != $(this)[0]) {
              $(this).attr('disabled', true);
            }
          })
        }
      } else {
        currentSeatsCount = parseInt(prevSeatsCount) + 1

        if (prevSeatsCount == 0) {
          $('.input-time-slots-' + breakout_time_slot_id).each(function(index) {
            if (currentElement[0] != $(this)[0]) {
              $(this).attr('disabled', false);
            }
          })
        }
      }

      if (currentSeatsCount == 0) {
        seatsText = 'sold out'
      } else {
        seatsText = currentSeatsCount + ' seats left'
      }

      $('.' + seatsCountClass).text(seatsText);
      $('.' + seatsCountClass).attr('data-availableseats', currentSeatsCount);
    });
  }

  next() {
    this.indexValue = this.indexValue + 1
    this.showCurrentTicket()
  }

  previous() {
    this.indexValue = this.indexValue - 1
    this.showCurrentTicket()
  }

  showCurrentTicket() {
    this.ticketTargets.forEach((element, index) => {
      if (index !== this.indexValue) {
        $(element).addClass('hide');
      } else {
        $(element).removeClass('hide');
      }
    });

    this.updatePagination();
  }

  updatePagination() {
    $('.current-ticket-index').text(this.indexValue + 1);

    if (this.indexValue == 0) {
      $('.prev-ticket-link').addClass('disable-link');
    } else {
      $('.prev-ticket-link').removeClass('disable-link');
    }

    if (this.indexValue == (this.ticketTargets.length - 1)) {
      $('.next-ticket-link').addClass('disable-link');
      $('.seat-from-submit').removeClass('hide');
    } else {
      $('.next-ticket-link').removeClass('disable-link');
    }
  }


  breakoutTitleClick(event) {
    $(this.breakoutWrapperTarget).slideToggle('fast');
    this.breakoutTitleChevronTarget.classList.toggle("arrow-up");
  }

  addTimeSlotLinkClick(event) {
    const ele          = $(event.currentTarget);
    const container_id = ele.data('association');
    const container    = `#${container_id} div.fields:last`

    $(container || 'body').find("[data-flatpickr-target='flatpickr_datetime']").flatpickr({
      altInput:   true,
      enableTime: true,
      altFormat:  "F j, Y h:i K",
      dateFormat: "Y-m-d h:i K"
    });
  }

  handleBreakoutPicUpload(event) {
    var input      = $(event.currentTarget);
    var preview    = $("#" + input.data('previewId'));
    var file       = input[0].files[0];
    var reader     = new FileReader();

    reader.onload  = function(e){
      preview.attr('src', e.target.result);
    };

    reader.readAsDataURL(file);
  }

  changeBreakoutPicture(e) {
    e.preventDefault();
    var break_id = $(e.currentTarget).data('break-id')
    $(`#breakoutPictureUpload-${break_id}`).trigger('click');
  }

  closeSeatInturrept() {
    $('.seat-interrupt-card-' + $(event.target).data('ticketid')).hide();
  }
}
