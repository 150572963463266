import { Controller } from "stimulus";
// TODO: Find a newer library
import ProgressBar from "progressbar.js";

export default class extends Controller {
  static targets = ["ticketItem", "userswrapper", "lnkGuestlistNext", "pagination", "checkbox"];

  connect() {
    // The listener for the Bootstrap event via jQuery
    $(this.element).on('hidden.bs.modal', (event) => {
      event.stopImmediatePropagation();
      this.onCloseModal();
    })
    $(this.element).on('shown.bs.modal', (event) => {
      event.stopImmediatePropagation();
      this.onOpenModal();
    })
  }

  initialize() {
    this.currentPage = null;
    this.currentTicketModal = null;
  }

  reset() {
    this.currentPage = null;
  }
  
  onCloseModal() {
    this.reset();
  }

  onOpenModal() {
    this.initializeRecipientsList();
  }


  initializeRecipientsList() {
    const emails = [];
    for (const c of this.checkboxTargets) {
      if (c.checked) {
        emails.push(c.dataset.email);
      }
    }
    if (emails.length > 0) {
      this.triggerUpdateRecipientsList(emails, false);
    }
  }

  updateRecipientsList(e) {
    const email = e.currentTarget.dataset.email;
    const remove = e.currentTarget.checked;
    this.triggerUpdateRecipientsList(email, remove)
  }

  triggerUpdateRecipientsList(recipients, remove) {
    const eventMails = new CustomEvent('update-recipients',{
      detail: {
        recipients,
        remove
      }
    })
    window.dispatchEvent(eventMails);
  }

  fetch1stPageOfGuestlist(ticketTypeId, lookupkey, currTicketPage) {
    this.currentTicketModal = currTicketPage;
    const next = this.userswrapperTarget.querySelector('.lnk-guestlist-next');
    next.href = `${next.href}&ticket_type_id=${ticketTypeId}&lookupkey=${lookupkey}`
    next.click();
  }

  scroll(e) {
    e.stopImmediatePropagation();
    const nextPage =  $(this.paginationTarget).find("a[rel='next']")[0];
    if (nextPage == null) return;
    if (this.currentPage == nextPage) return;
    const height = Math.max(this.element.scrollHeight, this.element.offsetHeight)
    if ($(this.element).scrollTop() >= height - $(this.element).innerHeight()) {
      const ticketTypeId = element.dataset.typeid;
      const lookupkey = element.dataset.lookupkey;
      nextPage.href = `${next.href}&ticket_type_id=${ticketTypeId}&lookupkey=${lookupkey}`
      nextPage.click();
      this.currentPage = nextPage;
    }
  }

  async showFilteredGuestListModal(e) {
    e.stopImmediatePropagation();
    const element = e.currentTarget;
    // const users = await JSON.parse(element.dataset.users);
    // const tickets = await JSON.parse(element.dataset.tickets);
    const type = element.dataset.tickettype;
    const topCount = element.dataset.ticketcount ? parseInt(element.dataset.ticketcount): element.dataset.ticketcountHeader;
    const bottomCount = element.dataset.tickettotal ? parseInt(element.dataset.tickettotal): element.dataset.tickettotalHeader;
    let percent = 0;
    let text = '';
    if (element.dataset.revenuePercentage) {
      text =  element.dataset.revenuePercentage;
      percent = element.dataset.revenuePercentage / 100;
    }
    else if (topCount !== 0 && bottomCount !== 0) {
      percent = topCount / bottomCount;
      text = Math.ceil(percent * 100)
    }

    const ticketTypeId = element.dataset.typeid;
    const lookupkey = element.dataset.lookupkey;
    const currTicketPage = `${ticketTypeId}${lookupkey}`;

    this.setProgress(percent, text);
    this.setHeading(type, topCount, bottomCount);
    $('#filtered_guest_list_modal').modal('show');
    if (this.currentTicketModal !== currTicketPage) {
      this.resetTable();
      this.fetch1stPageOfGuestlist(ticketTypeId, lookupkey, currTicketPage);
    }
  }

  resetTable() {
    const tbl = this.userswrapperTarget.querySelector('table tbody');
    tbl.innerHTML = '';
  }

  setHeading(heading, checkedInCount, totalCount){
    const title = this.userswrapperTarget.querySelector('.modal-heading');
    const fraction = this.userswrapperTarget.querySelector('.modal-heading-fraction');
    title.innerHTML = heading;
    fraction.innerHTML = `${checkedInCount}/${totalCount}`;
  }

  setProgress(percent, text) {
    const elem = this.userswrapperTarget.querySelector('.guest-cicle-wrapper .circle-box');
    elem.innerHTML = `<div class="progress" id="progress">
      <div class="inner">
        ${text}%
      </div>
    </div>`;
    const progressBar = new ProgressBar.Circle('#progress', {
      color: 'orange',
      trailColor: 'gray',
      strokeWidth: 10,
      duration: 2000, // milliseconds
      easing: 'easeInOut'
    });
    
    progressBar.animate(percent); // percent
  }
}