import { Controller } from 'stimulus'; 

export default class extends Controller {
  static values = {
    url: String
    // name: String,
    // image: String
  }

  share(event) {
    if (this._isTurboNativeApp) {
      event.preventDefault()
      event.stopImmediatePropagation()
      //window.TurboNativeBridge.postMessage("share", {"url": this.urlValue, "name": this.nameValue, "image": this.imageValue})
      window.TurboNativeBridge.postMessage("share", {"url": this.urlValue})
    }
  }

  get _isTurboNativeApp() {
    return navigator.userAgent.indexOf("Turbo Native") !== -1
  }
}
