import { Controller } from 'stimulus'; 
export default class extends Controller {

  delete_user_credit(event) {
    var credit_id = event.target.closest('a').dataset.creditId;
    event.preventDefault();
    event.stopImmediatePropagation();
    bootbox.confirm("Are you sure you want to delete this credit", function(confirmed) {    
      if(confirmed){
        $(`#user_credit_id_${credit_id}`).get(0).click();

      } 
    });
  }
}


