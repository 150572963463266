import { Controller } from 'stimulus'; 

export default class extends Controller {
  
  static targets = [
    "cardMenuContent",
    "cardMoreMenuContent",
    "titleLink"
  ];
  static values = { showLinks: Boolean, mouseoverValue: Boolean}
  
  // zevents/app/views/v2/kwivrr/profiles/_event_card.html.erb
  // zevents/app/views/v2/kwivrr/events/show.html.erb
  // zevents/app/views/v2/kwivrr/events/index.html.erb
  // zevents/app/views/v2/kwivrr/events/_forme_event_scope.html.erb
  // zevents/app/views/v2/kwivrr/calendar/_grid_view.html.erb
  // zevents/app/views/v2/kwivrr/calendar/_calendar_events.html.erb
  connect() {
    console.log("event_card_controller connect");
  }

  initialize() {
    this.showLinksValue = false;
    this.mouseoverValue = false;
  }

  menu_toggle(e) {
    e.preventDefault();
    console.log("event_card_controller menu_toggle");

    this.showLinksValue = !this.showLinksValue;    
    if ($(this.cardMoreMenuContentTarget).css('display') != 'none') {
      $(this.cardMoreMenuContentTarget).hide(); // hide other Menu if it is open
    }
    $(this.cardMenuContentTarget).slideToggle();
  }

  menu_more_toggle(e) {
    e.preventDefault();
    console.log("event_card_controller menu_more_toggle");

    if ($(this.cardMenuContentTarget).css('display') != 'none') {
      $(this.cardMenuContentTarget).hide(); // hide other Menu if it is open
    }
    $(this.cardMoreMenuContentTarget).slideToggle();
  }

  // click anywhere on the event card (not necessarily the link) will
  // act as a link-click.
  body_click(e) {
    // console.log("event card body_click");

    // click on defined link (or svg element nested under a link)
    let closest_link = e.target.closest("a");
    let click_path = closest_link && closest_link.getAttribute("href");
    if (click_path) {
      console.log(click_path);
      // default behavior
    } else {
      // click on other area of card body
      let title_path = this.titleLinkTarget.getAttribute("href");
      console.log(title_path);

      // assuming this link has been defined as a standard back-end
      // link, with no special JS behavior.
      Turbo.visit(title_path);
    }
  }
}