import { Controller } from "stimulus";
import toastr from "toastr";

export default class extends Controller {
  static targets = [
    "subject",
    "message",
    "form",
    "dropzone"
  ];
  
  connect() {
    console.log("email-guestlist controller connected");
    // The listener for the Bootstrap event via jQuery
    $(this.element).on('hidden.bs.modal', (event) => {
      event.stopImmediatePropagation();
      this.onCloseModal();
    })
  }

  initialize() {
    this.filenames = [];
    this.recipients = [];
  }

  reset() {
    this.filenames = [];
    this.recipients = [];
    this.subjectTarget.value = '';
    this.messageTarget.value = '';
    const removeFiles = new CustomEvent('remove-file-list');
    window.dispatchEvent(removeFiles);
  }

  onCloseModal() {
    this.reset();
  }

  showEmailGuestListModal() {
    $('#email_guest_list_modal').modal('show');
  }

  updateFileUploadedList(event) {
    this.filenames.push(event.detail.filename);
  }

  updateRecipients(e) {
    const email = e.detail.recipients;
    const checked = e.detail.remove;

    if (Array.isArray(email)) {
      this.recipients =  email;
    } else {
      if (checked) {
        this.recipients.push(email);
      } else {
        this.recipients = this.recipients.filter(e => e !== email)
      }
    }
  }

  submit(e){
    e.preventDefault()
    if (this.recipients.length === 0) {
      toastr.warning("Please, select your recipients!", "Warning");
    }
    for (const r of this.recipients) {
      $("#email_guest_list_modal form").append(`<input type="hidden" name="recipients[]" value="${r}">`);
    }
    for (const f of this.filenames) {
      $("#email_guest_list_modal form").append(`<input type="hidden" name="filenames[]" value="${f}">`);
    }
    this.formTarget.submit();
  }

  dropzoneClick() {
    this.dropzoneTarget.click();
  }
}