import { Controller } from "stimulus";

export default class extends Controller {
  static values = {
    attendees1stPageLoaded: Boolean,
    interested1stPageLoaded: Boolean,
    attendeesUrl: String, 
    interestedUrl: String
  }
  static targets = ["lnkAttendingNext", "lnkInterestedNext"];
  
  connect() {
    console.log("guestlist controller connected");

    // TODO: We want the modal scrollbar to be at the top when it is reopened (i.e. opened-closed-opened)
    // But below code is not working.
    $('#guest_list_modal').on('shown.bs.modal', () => {
      $('#guest_list_modal').scrollTop(0);
    })
  }

  initialize() {
    this.attendees1stPageLoadedValue = false;
    this.interested1stPageLoadedValue = false;
  }

  fetch1stPageOfAttendees() {
    // Fetch 1st page of Attendees
    if (this.hasLnkAttendingNextTarget) {
      this.lnkAttendingNextTarget.click()
      this.attendees1stPageLoadedValue = true;
    }
  }

  fetch1stPageOfInterestedUsers() {
    // Fetch 1st page of Interested Users
    if (this.hasLnkInterestedNextTarget) {
      this.lnkInterestedNextTarget.click()
      this.interested1stPageLoadedValue = true;
    }
  }

  showGuestListModal() {
    $('#guest_list_modal').modal('show');
    this.showAttendeesList();
  }

  showAttendeesList(){
    if (!this.attendees1stPageLoadedValue) this.fetch1stPageOfAttendees();

    $('#ticket_attendees_list').show();
    $('#interested_users_list').hide();

    $('#btnShowAttendees').addClass('active');
    $('#btnShowInterestedUsers').removeClass('active');
  }

  showInterestedUsersList(){
    if (!this.interested1stPageLoadedValue) this.fetch1stPageOfInterestedUsers();

    $('#ticket_attendees_list').hide();
    $('#interested_users_list').show();

    $('#btnShowAttendees').removeClass('active');
    $('#btnShowInterestedUsers').addClass('active');
  }

  
}