import { Controller } from 'stimulus';

export default class extends Controller {
  static values = {
    pollType: String,
    singleAnswer: Number,
    multipleAnswers: Array,
    textAnswer: String
  }

  static targets = [ 'singleAnswer', 'multipleAnswers', 'textAnswer', 'save', 'form', 'hiddenFieldAnswer' ]

  connect() {
    console.log(`this.pollTypeValue = ${this.pollTypeValue}`);
  }

  setSingleAnswerValue(event) {
    event.preventDefault();
    const ele = event.target;
    this.singleAnswerValue = parseInt(ele.dataset.optionId);
  }

  singleAnswerValueChanged() {
    if (!this.singleAnswerTargets) return;

    const selectedOption = this.singleAnswerTargets.find(x => parseInt(x.dataset.optionId) == this.singleAnswerValue);
    if (selectedOption) {
      selectedOption.classList.add('selected');
      const notSelectedOptions = this.singleAnswerTargets.filter(x => parseInt(x.dataset.optionId) != this.singleAnswerValue);
      notSelectedOptions.forEach(x => x.classList.remove('selected'));
    }

    this.saveTarget.disabled = (this.singleAnswerValue ? false : true);
    console.log(`this.singleAnswerValue = ${this.singleAnswerValue}`);
  }

  setMultipleAnswersValue(event) {
    event.preventDefault();

    // For multiple_option, we need the buttons to have 'toggle' ability.
    // So, if this option is already in the list, we have to remove it, otherwise we have to add it.
    const ele = event.target;
    if (this.multipleAnswersValue.includes(parseInt(ele.dataset.optionId))) {
      this.multipleAnswersValue = this.multipleAnswersValue.filter(x => x != parseInt(ele.dataset.optionId));
    } else {
      this.multipleAnswersValue = [...this.multipleAnswersValue, parseInt(ele.dataset.optionId)];
    }
  }

  multipleAnswersValueChanged() {
    if (!this.multipleAnswersTargets) return;

    this.multipleAnswersTargets.forEach(x => {
      if (this.multipleAnswersValue.includes(parseInt(x.dataset.optionId))) {
        x.classList.add('selected');
      } else {
        x.classList.remove('selected');
      }
    })

    this.saveTarget.disabled = (this.multipleAnswersValue.length == 0);
    console.log(`this.multipleAnswersValue = ${this.multipleAnswersValue}`);
  }

  setTextAnswerValue(event) {
    this.textAnswerValue = event.target.value.trim();
  }

  textAnswerValueChanged() {
    this.saveTarget.disabled = (this.textAnswerValue ? false : true);
  }

  // We need to construct the below Hash for 'answer' before submitting the form:
  // { selected_option_ids: 1, text: nil } --> single answer
  // { selected_option_ids: [1,2], text: nil } --> multiple answers
  // { selected_option_ids: nil, text: "ssfssss" } --> text answer
  save() {
    let h = {}
    if (this.pollTypeValue == 'single_option') {
      h = { selected_option_ids: this.singleAnswerValue, text: null }
    } else if (this.pollTypeValue == 'multiple_options') {
      h = { selected_option_ids: this.multipleAnswersValue, text: null }
    } else if (this.pollTypeValue == 'text') {
      h = { selected_option_ids: null, text: this.textAnswerValue }
    }
    this.hiddenFieldAnswerTarget.value = JSON.stringify(h)
    // this.formTarget.submit();
    Turbo.navigator.submitForm(this.formTarget);
  }
}