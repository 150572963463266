import consumer from "../consumer"


  consumer.subscriptions.create({channel: 'Zevents::EventNotificationChannel'}, {  
    connected() {
    // Called when the subscription is ready for use on the server
      console.log("connected to user notification room")
    },

    disconnected() {
      // Called when the subscription has been terminated by the server
    },

    received(data) {
      // Called when there's incoming data on the websocket for this channel   
      
      var count = data.time_interval; 
      if(window.location.href.indexOf('host_connect') > -1) {
        return false;     
      } else {
        $('.admin-header > .row').after('<div class="alert alert-primary alert-dismissible fade show text-center" role="alert"><strong><a href="/events_listing/'+data.event_id+'/host_connect">'+data.event_name+'</a></strong> is going to start in <span id="time-count">'+ count +'</span> minitues.<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button></div>');
      }
      var alertInterval = setInterval(function() {
        count -= 1;       
        $('#time-count').text(count);
        if(count == 0) {
          clearInterval(alertInterval);
          $(".admin-header .alert").alert('close');
        }

      }, 60000)
      
    //   sessionStorage.getItem("cart_json");
    } 
  
});

