// https://animate.style/
// Below code is a slightly modified version of what is found on the above website.
const animateCSS = (element, animation, prefix = 'animate__') => {
  let node = null;
  // We create a Promise and return it
  return new Promise((resolve, reject) => {
    const animationName = `${prefix}${animation}`;
    // const node = document.querySelector(element);
    if (typeof element == 'object') {
      node = element;
    } else if (typeof element == 'string') {
      node = document.querySelector(element);
    }

    node.classList.add(`${prefix}animated`, animationName);

    // When the animation ends, we clean the classes and resolve the Promise
    function handleAnimationEnd(event) {
      event.stopPropagation();
      node.classList.remove(`${prefix}animated`, animationName);
      resolve('Animation ended');
    }

    node.addEventListener('animationend', handleAnimationEnd, {once: true});
  });
}

export default animateCSS;