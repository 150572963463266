import { Controller } from 'stimulus';
import { get } from "@rails/request.js"
export default class extends Controller { 
  static values = {
    forMeUrl: String,
    profileId: String,
    loadItemForm: String,
  }

  connect() {
    console.log('for-me connect');

    if(this.loadItemFormValue){
      $('.modal').modal('hide')
    }

    var slideWidth = 675;
    var slideMargin = 15;
    var profileSlideWidth = 425;
    var profileslideMargin = 20;
    var eventslideWidth = 150;
    var shopping_item_width = 280;
    var responsiveFootageShopping = 4;
    var marginRight = 50;

    if(window.matchMedia( "(max-width: 375px)" ).matches) {
      var slideWidth = 315;
      var profileSlideWidth = 275;	    
    } else if(window.matchMedia("(max-width: 480px)").matches) {
      var slideWidth = 325;
      var profileSlideWidth = 310;		
    } else if(window.matchMedia("(max-width: 768px)").matches) {
      var slideWidth = 385;
      var profileslideMargin = 20;		
      var profileSlideWidth = 320;
      var eventslideWidth = 100;
    } else if(window.matchMedia("(max-width: 1024px)").matches) {
      var slideWidth = 530;	
      var profileSlideWidth = 405;
      var eventslideWidth = 125;	
    }

    $('#my_events').flexslider({
      animation: "slide",
      animationLoop: false,
      itemWidth: slideWidth,
      itemMargin: slideMargin,
      slideshow: false,
      controlNav: false
    });

    $('#profile_events').flexslider({
      animation: "slide",
      animationLoop: false,
      itemWidth: profileSlideWidth,
      itemMargin: profileslideMargin,
      slideshow: false,
      controlNav: false
    });

    // #featuredItems
    $('#shoppingItems').flexslider({
      animation: "slide",
      animationLoop: false,
      itemWidth: shopping_item_width,
      minItems: responsiveFootageShopping,
      itemMargin: marginRight,
      slideshow: false,
      controlNav: false
    });

    $('#filter_buttons').flexslider({
      animation: "slide",
      animationLoop: false,
      itemWidth: 100,
      itemMargin: 15,
      slideshow: false,
      controlNav: false
    });
    
    $('#filter_event_buttons').flexslider({
      animation: "slide",
      animationLoop: false,
      itemWidth: eventslideWidth,
      itemMargin: 15,
      slideshow: false,
      controlNav: false
    });
    feather_icons.replace();

    setTimeout(function(){ $(".card-more-menu").slideUp('fast')},100);
  }

  load_events_scope(e) {
    var filter = e.currentTarget.dataset.filter;
    let params = new URLSearchParams()
    params.append("filter", filter)
    if(this.profileIdValue) {
     params.append("id", this.profileIdValue)
    }

    $('.slides').find('*').removeClass('btn-primary');
    e.currentTarget.classList.add("btn-primary")
   
    get(`${this.forMeUrlValue}?${params}`, {
      responseKind: "turbo-stream"
    })
  }
}