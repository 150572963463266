import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [ "previous", "next", "skip", "completed", "slide" ];
  static values = { page: Number, noOfPages: Number, signInCount: Number, userId: String };

  connect() {
    const alreadySeenByUser = localStorage.getItem(`display_tutorial_overlay_modal_${this.userIdValue}`);
    if ((this.signInCountvalue == 1) && !alreadySeenByUser) {
      $('#tutorial_overlay_modal').modal({
        backdrop: 'static'
      });
    }
  }

  initialize() {
    this.pageValue = 1;
  }

  next() {
    this.pageValue = Math.min(this.pageValue + 1, this.noOfPagesValue);
  }

  prev() {
    this.pageValue = Math.max(this.pageValue - 1, 1);
  }

  showCurrentPage() {
    // Assign correct iframe src to slide if not assigned
    const currentSlide = this.slideTargets[this.pageValue - 1];
    const $slide_iframe = $(currentSlide).find('iframe');
    if (!$slide_iframe.attr('src')) {
      $slide_iframe.attr('src', window.tutorial_slides_iframe_src_array[this.pageValue - 1]);
    }

    // Show Current Slide
    this.slideTargets.forEach((element, index) => {
      element.hidden = !(index+1 == this.pageValue);
    })
    
    // Hide on 1st page
    this.previousTarget.hidden = !(this.pageValue - 1 > 0);

    // Hide if Count is 1 OR Reached Last Slide
    this.nextTarget.hidden = !((this.noOfPagesValue > 1) && (this.pageValue + 1 <= this.noOfPagesValue));
    
    // Show on Last page
    this.completedTarget.hidden = !(this.pageValue == this.noOfPagesValue);

    // Show if there are multiple Slides and Not on Last Slide (because Done will be visible in those cases)
    this.skipTarget.hidden = !((this.noOfPagesValue > 1) && (this.pageValue != this.noOfPagesValue));
  }

  pageValueChanged() {
    this.showCurrentPage();
  }

  skip() {
    localStorage.setItem(`display_tutorial_overlay_modal_${this.userIdValue}`, true);
  }

  complete() {
    localStorage.setItem(`display_tutorial_overlay_modal_${this.userIdValue}`, true);
  }

}


