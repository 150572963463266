import { Controller } from "stimulus"
import i18n from "../packs/i18n"

export default class extends Controller {

  static values = {
    baseUri: String,
    nexiopayToken: String,
    serverUrl: String
  }

  static targets = [ "nexioIframe" ]

  connect() {
    var nexio_token = this.nexiopayTokenValue;
    console.log("Nexio token::"+nexio_token);
    var base_uri = this.baseUriValue;
    var server_url = this.serverUrlValue;
    var iframeUrl = base_uri+"/pay/v3/saveCard?token="+nexio_token;
    this.nexioIframeTarget.src = iframeUrl;

    var card_submitted = false;
    var error_occured = false;
    var is_card_saved = false;

    $('input[type=submit][name=commit]').off('click');
    $('input[type=submit][name=commit]').on('click', (event) => {
      this.reset_error_messages();
      var el = event.target;
      var formIsValid = true;

      if ($('#zevents_event_order_preferred_language').find(":selected").val() === '') {
        $('div.prefered-lang-form-group').addClass('invalid-form-group');
        $('#zevents_event_order_preferred_language').addClass('is-invalid');
        $('div.prefered-lang-form-group').append('<div class="invalid-feedback">Prefered language cannot be blank</div>')

        formIsValid = false
      }

      if ($('#zevents_event_order_external_agent_id').val() === '') {
        $('div.zevents_event_order_external_agent_id').addClass('invalid-form-group');
        $('input#zevents_event_order_external_agent_id').addClass('is-invalid');
        $('div.zevents_event_order_external_agent_id').append('<div class="invalid-feedback">Agent ID cannot be blank</div>')

        formIsValid = false
      }

      const pollDropdowns = document.querySelectorAll('[id^="poll_answer_"]');
      pollDropdowns.forEach((pollDropdown) => {
        if (pollDropdown && (pollDropdown.dataset.required == 'true')) {
          if (pollDropdown.value == '') {
            const $pollDDdiv = $(pollDropdown).closest('div.form-group');
            $pollDDdiv.addClass('invalid-form-group');
            $pollDDdiv.find('.form-control').addClass('is-invalid');
            $pollDDdiv.append(`<div class="invalid-feedback">${i18n.t('field_cannot_be_blank')}</div>`)

            formIsValid = false;
          }
        }
      })

      const specialDropdown1 = document.querySelector('#extra_dropdown1_with_configurable_values');
      if (specialDropdown1 && (specialDropdown1.dataset.required == 'true')) {
        if (specialDropdown1.value == '') {
          const $specialDDdiv = $('div.form-group#extra_dropdown_with_configurable_values')
          $specialDDdiv.addClass('invalid-form-group');
          $specialDDdiv.find('.form-control').addClass('is-invalid');
          $specialDDdiv.append('<div class="invalid-feedback">This field cannot be blank</div>')

          formIsValid = false;
        }
      }

      const toc_checkbox = document.querySelector('#toc_checkbox');
      if (toc_checkbox) {
        if (!toc_checkbox.checked) {
          const chkboxContainer = toc_checkbox.closest('.form-check');
          $(chkboxContainer).addClass('invalid-form-group');
          $(toc_checkbox).addClass('is-invalid');
          $(toc_checkbox).append('<div class="invalid-feedback">Please accept the Terms and Conditions before proceeding</div>');
          formIsValid = false;
        }
      }

      if (formIsValid) {
        if($("[name='credit_card_option']:checked").val()=="new_card" && $('.credit-card-section').is(":visible")) {
          if(!card_submitted){
            event.preventDefault();
            // since we are blocking the event to bubble and returning a false, jquery-ujs wouldn't do the following.
            el.setAttribute("disabled", true);
            el.classList.add("disabled");
            el.value = el.dataset.disableWith;

            var nexioFrame = document.getElementById('nexioFrame');
            nexioFrame.contentWindow.postMessage('posted', base_uri);
            card_submitted = true;
            return false; // keeps the form from auto submittings
          }
        }
      } else {
        toastr.error('Please enter required fields');
        return false
      }
    });

    $(window).on('message', function messageListener(e) {
      var event = e.originalEvent;

      // Below is code to handle 'message's from Nexiopay's Iframe for New Credit Card
      if($("[name='credit_card_option']:checked").val()=="new_card"){
        console.log("nexiopay data..." + event.data.event);
        if (event.origin === base_uri) {
          if (event.data.event === 'error'){
            if ("error" in event.data.data && !error_occured){
              var msg_from_nexiopay = lookup(event.data.data, "message");
              if (msg_from_nexiopay) {
                toastr.error(msg_from_nexiopay);
              }
              reload_nexiopay_form(event);
              error_occured = true;
            }
            // Handle if any other error responses
          }
          if (event.data.event === 'success'){
            // Handle any success response
          }
          if (event.data.event === 'loaded') {
          //handle form loaded state
          }
          if (event.data.event === 'formValidations') {
            if(!event.data.data.isFormValid) {
              $(".event_order").attr('disabled', false).removeClass('disabled').addClass('primary');
              $(".event_order").val($(".event_order").data('enableWith'));
              card_submitted = false;
            }
          }

          if (event.data.event === 'cardSaved' && !this.is_card_saved && !this.error_occured) {
            console.log('cardSaved event received from Nexiopay\'s iframe');
            // We are sometimes getting multiple 'cardSaved' events, so we need to debounce
            if (window.debounceSaveCard) window.clearTimeout(window.debounceSaveCard);
            window.debounceSaveCard = setTimeout(() => {
              console.log('calling save_nexio_credit_card()');
              save_nexio_credit_card(event.data.data);
              is_card_saved = true;
            }, 500);
          }          
        }
      }

      // Respond to 3ds messages ("nexio3ds_success" and "nexio3ds_fail")
      // These messages are from our Modal Popup (iframe when callback from Nexiopay reaches callback_nexio3ds.html.erb)
      if (event.origin === server_url) {
        console.log('message data from our iframe - ' + JSON.stringify(event.data));
        if(event.data.payload === "nexio3ds_success"){
          // debugger;
          const closeModalEvent = new CustomEvent('close-global-modal');
          window.dispatchEvent(closeModalEvent);
          // if (event.data.what_to_do == 'redirect') {
            Turbo.visit(event.data.value);
          // }
        } else if(event.data.payload === "nexio3ds_fail"){
          toastr.error(event.data.msg);
          reload_nexiopay_form(event);
        }
      }

    });

    function save_nexio_credit_card(event_data){
      $("#zevents_event_order_credit_card_attributes_name").val(event_data.card.cardHolderName);
      $("#zevents_event_order_credit_card_attributes_card_number").val(event_data.token.lastFour);
      $("#zevents_event_order_credit_card_attributes_expiry_date").val(event_data.card.expirationMonth+ "/" +event_data.card.expirationYear);
      $("#zevents_event_order_credit_card_attributes_cvv").val(event_data.card.securityCode);    
      $("#zevents_event_order_credit_card_attributes_cc_type").val(get_cc_type(event_data.token.cardType));
      $("#zevents_event_order_credit_card_attributes_token").val(event_data.token.token);    
      var address_fields = event_data.data.customer;
      $("#zevents_event_order_credit_card_attributes_address").val(address_fields.billToAddressOne);
      $("#zevents_event_order_credit_card_attributes_address2").val(address_fields.billToAddressTwo);
      $("#zevents_event_order_credit_card_attributes_city").val(address_fields.billToCity);
      $("#zevents_event_order_credit_card_attributes_state").val(address_fields.billToState);
      $("#zevents_event_order_credit_card_attributes_country").val(address_fields.billToCountry); 
      $("#zevents_event_order_credit_card_attributes_zipcode").val(address_fields.billToPostal);
      // Turbo intercepts form submits but it doesn't recognize form.submit() which is causing
      // issues in turbo mobile app. so hacking it to use Turbo's internal method.

      Turbo.navigator.submitForm(document.getElementsByClassName('edit_zevents_event_order')[0]);
    }

    function reload_nexiopay_form(event){
      Turbo.visit(window.location.href);
      // $.ajax({
      //   type: "GET",
      //   dataType: "script",
      //   url: "<%= edit_zevents_event_order_path(@event_order, state: @event_order.current_step) %>",
      //   data: {
      //     enable_new_credit_card_on_reload: true,
      //     cc_error_response: event.data,
      //     email: "<%= current_user&.email %>",
      //     name: "<%= current_user&.display_name %>"
      //   }
      // });
    }

    function get_cc_type(type){
      if(type == null){
        return "" 
      }
      var cc_types = {"masterCard": "master", "americanExpress": "american_express", "diners": "diners_club"}
      return (cc_types[type] || type)
    }

    function lookup(obj, k) {
      for (var key in obj) {
        var value = obj[key];

        if (k == key) {
          // return [k, value];
          return value;
        }

        if (typeof(value) === "object" && !Array.isArray(value)) {
          var y = lookup(value, k);
          if (y && y[0] == k) return y;
        }
        if (Array.isArray(value)) {
          // for..in doesn't work the way you want on arrays in some browsers
          //
          for (var i = 0; i < value.length; ++i) {
            var x = lookup(value[i], k);
            if (x && x[0] == k) return x;
          }
        }
      }
      return null;
    }

    const pollDropdowns = document.querySelectorAll('[id^="poll_answer_"]');
    pollDropdowns.forEach((pollDropdown) => {
      if (pollDropdown && (pollDropdown.dataset.required == 'true')) {
        $(pollDropdown).on('change', () => {
          if ($(pollDropdown).val() != '') {
            this.reset_error_message_for_poll_dropdown(pollDropdown);
          }
        });
      }
    })

    const specialDropdown1 = document.querySelector('#extra_dropdown1_with_configurable_values');
    if (specialDropdown1) {
      $(specialDropdown1).on('change', () => {
        if ($(specialDropdown1).val() != '') {
          this.reset_error_message_for_special_dropdown1();
        }
      });
    }

    const toc_checkbox = document.querySelector('#toc_checkbox');
    if (toc_checkbox) {
      $('#toc_checkbox').on('change', () => {
        if (document.querySelector('#toc_checkbox').checked) {
          this.reset_error_message_for_toc_checkbox();
        }
      });
    }
  } // end of connect

  reset_error_messages(){
    $('.invalid-feedback').remove();
    $('div.zevents_event_order_external_agent_id').removeClass('invalid-form-group');
    $('input#zevents_event_order_external_agent_id').removeClass('is-invalid');
    $('div.prefered-lang-form-group').removeClass('invalid-form-group');
    $('#zevents_event_order_preferred_language').removeClass('is-invalid');

    this.reset_error_message_for_special_dropdown1();

    const pollDropdowns = document.querySelectorAll('[id^="poll_answer_"]');
    pollDropdowns.forEach((pollDropdown) => {
      this.reset_error_message_for_poll_dropdown(pollDropdown);
    })
  }

  reset_error_message_for_special_dropdown1() {
    // Special dropdown1
    const $specialDDdiv = $('div.form-group#extra_dropdown_with_configurable_values')
    $specialDDdiv.removeClass('invalid-form-group');
    $specialDDdiv.find('.form-control').removeClass('is-invalid');
  }

  reset_error_message_for_toc_checkbox() {
    const toc_checkbox = document.querySelector('#toc_checkbox');
    if (toc_checkbox) {
      const chkboxContainer = toc_checkbox.closest('.form-check');
      $(chkboxContainer).removeClass('invalid-form-group');
      $(toc_checkbox).removeClass('is-invalid');
    }
  }

  reset_error_message_for_poll_dropdown(ele) {
    const $pollDDdiv = $(ele).closest('div.form-group');
    $pollDDdiv.removeClass('invalid-form-group');
    $pollDDdiv.find('.form-control').removeClass('is-invalid');
  }

}