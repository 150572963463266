import { Controller } from "stimulus";
import i18n  from "../packs/i18n"

export default class extends Controller {
  static targets = ["ticketId"];
  static values = { userId: String };

  checkin_attendee() {
    var ticket_id = this.ticket_id;
    var credentials_id = $("#zevents_event_ticket_credentials_id").val();
    var message = `<ol>
      <li>Are you experiencing any of the following symptoms?,</li>
      <ul>
        <li> Severe difficulty breathing (deeply struggling for breath, can only speak in single words)</li>
        <li>Severe chest pain</li>
        <li>A fever</li>
        <li>New cough</li>
        <li>Loss of consciousness</li>
        <li>Muscle aches</li>
        <li>Fatigue</li>
        <li>Headache</li>
        <li>Sore throat</li>
        <li>Runny nose</li>
      </ul>
      <li>In the last 14 days, have you been diagnosed with COVID-19 or are awaiting a COVID-19 test result?</li>
      <li>In the last 14 days, have you been in contact with someone who has been diagnosed with COVID-19?</li>
      <li>You understand there is a risk of coming into contact with Covid-19 during your visit at this event.</li>
      <li>You acknowledge that no one at this event will be held liable if you come into contact with COVID-19 and/or get sick following your visit.</li>
    </ol>`;
    bootbox.confirm({
      message: message,
      size: "large",
      buttons: {
        confirm: {
          label:
            "Attendee acknowledges their role in this event and accepts to COVID-19 risk at this event.",
          className: "btn-success",
        },
        cancel: {
          label: "No",
          className: "btn-danger",
        },
      },
      callback: function (result) {
        if (result) {
          $.get(
            `/event_ticket/checkin_attendee?event_ticket_id=${ticket_id}&credentials_id=${credentials_id}&accept_duplicate=false`
          );
        }
      },
    });
  }

  update_user_rank(event) {
    var ticket_id = this.ticket_id;
    var rank_id = event.target.value;
    var user_id = this.user_id;
    $.ajax({
      url: "/admin/users/" + user_id + "/update_rank",
      type: "PUT",
      data: { rank_id: rank_id },
      success: function (result) {},
    });
  }

  toggle_ticket_reciept(event) {
    event.preventDefault();
    $(this.element).children().find(".modre-details").slideToggle("fast");
    $(this.element).children().find("svg").toggleClass("toggle-arrow");
  }

  select_ticket(event) {
    $(`input[type=checkbox][name=select_ticket]`)
      .not($(event.target))
      .prop("checked", false);

    this.get_ticket_options();
    $('.order-tables [class*="data-body"]').removeClass("data-body-row");
    $(`input[type=checkbox][name=select_ticket]:checked`).closest('[class="data-body"]').addClass('data-body-row');
  }

  get_ticket_options() {
    if( $(`input[type=checkbox][name=select_ticket]:checked`).is('*')){
      var ticket_id = $(`input[type=checkbox][name=select_ticket]:checked`).val();
      $("#ticket_actions_" + ticket_id)[0].click();
    }
  }

  ticket_action(event) {
    var ticket_id = $(`input[type=checkbox][name=select_ticket]:checked`).val();
    var action = event.target.value;
    $(event.target).val("");
    if (ticket_id == undefined) {
      bootbox.alert({
        message: i18n.t('select_ticket'),
        locale: document.querySelector("body").getAttribute("data-locale")
      });
    } else {
      $("#" + action + "_" + ticket_id)[0].click();
    }
  }

  get ticket_id() {
    return this.ticketIdTarget.value;
  }

  get user_id() {
    return this.userIdValue;
  }

  display_credits_option() {
    $("#confirmation_message").hide(); 
    if ($('#issue_refund').is(':checked')) {
      $("#only_credits_option").removeClass('d-none');
      $("#credit_card_refund_message").show();
    } else{
      $("#only_credits_option").addClass('d-none');
      $("#credit_card_refund_message").hide();
    }

  }

  display_credits_refund_message() {
    $("#confirmation_message").hide(); 
    if ($('#issue_only_credits').is(':checked')) {
      $("#credits_refund_message").show();
      $("#credit_card_refund_message").hide();
    } else{
      $("#credits_refund_message").hide();
      $("#credit_card_refund_message").show();
    }

  }

  display_confirmation_dialog(){
    if( !$('#issue_refund').is(':checked') && !$('#issue_only_credits').is(':checked')  &&
     ($('#confirmation_message').css('display') == 'none' || $('#confirmation_message').hasClass('d-none') ) ) {
      $("#confirmation_message").show();      
      return false;    
    } 
    else {
      $("#cancel_ticket").click();
    }
  }


  after_submission_of_cancel_form(event) {
    setTimeout(function(){
      var ticket_errors = $('#ticket_errors').val();
      if (ticket_errors ) {
        $("#issue_refund").attr('checked', true);
        $("#only_credits_option").removeClass('d-none');
        $("#credit_card_refund_message").show();
        toastr.error(ticket_errors);  
      }
    }, 10);  
    

  } 
}
