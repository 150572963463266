import { Controller } from "stimulus"
import i18n  from "../packs/i18n"

export default class extends Controller {
  static targets = [
    "eventTypeSelection",
    "searchModal",
    "hiddenLmsLink",
    "parentLmsLink"
  ]

  connect() {
    // console.log("navbar_controller connect");
  }

  // Navbar search and navbar event form are mutually exclusive.
  toggleEventPopup(event) {
    event.preventDefault(); // "#"
    event.stopImmediatePropagation(); // catch rogue jQuery listeners.

    // console.log("toggleEventPopup click");

    this.eventTypeSelectionTarget.classList.toggle("open-event-popup");

    // TODO: can we avoid JQuery 'modal' dependency?
    $('#search_modal').modal('hide');
  }

  // Navbar search and navbar event form are mutually exclusive.
  toggleSearchModal(event) {
    event.preventDefault(); // "#"
    event.stopImmediatePropagation(); // catch rogue jQuery listeners.
    // console.log("toggleSearchModal click");

    this.eventTypeSelectionTarget.classList.remove("open-event-popup");

    // TODO: can we avoid JQuery 'modal' dependency?
    $("#search_modal").modal("toggle");
  }

  followLmsLink(event) {
    event.preventDefault(); // "#"
    event.stopImmediatePropagation(); // catch rogue jQuery listeners.
    // console.log("followLmsLink click");
  
    // event.srcElement will be the <i> SVG, not the <a>
    const mobileOrigin = this.parentLmsLinkTarget.dataset["origin"];

    if (window.location !== window.parent.location) {
      // mobile: exit current iframe, return to origin
      window.parent.postMessage("openNativeMobileLMS", mobileOrigin);
    } else {
      // desktop: follow link to LMS.
      this.hiddenLmsLinkTarget.click();
    }
  }
}
