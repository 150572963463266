import { Controller } from 'stimulus';
import i18n from "../packs/i18n"

export default class extends Controller {
  static targets = ["input", "preview"]
  static values = {}

  connect() {
    console.log("featured_items connect");
  }

  processFile() {
    // console.log("processFile");
    var input = this.inputTarget;
    // console.log(input);
    var preview_area = this.previewTarget;
    // console.log(preview_area);
    if (input.files && input.files[0]) {
      console.log('input file', input, input.files[0]);
      var reader = new FileReader();
      reader.onload = function(e) {
        preview_area.src = e.target.result;
      }
      reader.readAsDataURL(input.files[0]);
    } else {
      console.log('no image selected');
    }
  }
}