import { Controller } from 'stimulus';
export default class extends Controller {
  static targets = ["kwivrrCalendar", "eventID"]

  connect() {
    console.log("calendar connect");

    if ($('#calendarWrapper').length > 0) {
      var scrollValue = 700;
      if(window.matchMedia( "(max-width: 768px)" ).matches) {
        scrollValue = 400;
      }

      var calendarEl = this.kwivrrCalendarTarget;
      this.calendar = new FullCalendar.Calendar(calendarEl, {
        plugins: [ dayGridPlugin, interactionPlugin ],
        initialView: 'dayGridMonth',
        headerToolbar: {
          left: 'prev',
          center: 'title',
          right: 'next' //'dayGridMonth,timeGridWeek,listWeek'
        },
        dayHeaderFormat: {
          weekday: 'narrow'
        },
        events: '/v2/calendar/calendar_events.json',
        dateClick: function(info) {
          var dayEl = info.dayEl;
          if ($(dayEl).find('.fc-daygrid-day-top').hasClass('event-date-highlight')) {
            $('.fc-day').each(function(){
              $(this).find('.fc-daygrid-day-top').removeClass('selected');
              $('#my_streams').slideDown('fast');
              $("html, body").stop().animate({scrollTop: scrollValue}, 500, 'linear')
            });

            $(dayEl).find('.event-date-highlight').toggleClass('selected');
          } else {
            $('#my_streams').slideUp('slow');
          }
          var clickedDate = info.date;
          var url = '/v2/calendar/calendar_events?clicked_date=' + clickedDate;
          $('#calendar-turbo-link').attr('href',url);
          $('#calendar-turbo-link')[0].click();
        },
        eventDidMount: function(info) {
          var dayElement = $(info.el).parents('.fc-daygrid-day-events').prev();
          dayElement.addClass('event-date-highlight');
        }
      });

      this.calendar.render();
    } // calendarWrapper check end

    var upcomingSlideWidth = 570;
    var upcominSlideMargin = 15;

    if(window.matchMedia( "(max-width: 992px)" ).matches) {
      var upcomingSlideWidth = 350;
    }

    if(window.matchMedia( "(max-width: 480px)" ).matches) {
      var upcomingSlideWidth = 325;
    }

    $(".flexslider, .carousel").flexslider({
      animation: "slide",
      animationLoop: false,
      itemWidth: upcomingSlideWidth,
      itemMargin: upcominSlideMargin,
      slideshow: false,
      controlNav: false
    });

    this.triggerResizeImages();
  } // Connect end

  triggerResizeImages() {
    // https://stimulus.hotwired.dev/reference/controllers#directly-invoking-other-controllers
    var firstImg = document.querySelector('[data-controller="image"]');
    if (firstImg) {
      firstImg.controller_image.resizeImages({ debug: "calendar triggerResizeImages" });
    }
  }
}