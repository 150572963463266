import { Controller } from 'stimulus'; 
export default class extends Controller {

  connect(){
    console.log("input counter controller connected");

    let remainingCount
    const titleMax       = 50
    const messageMax     = 150
    const titleCounter   = document.querySelector("#title-counter")
    const messageCounter = document.querySelector("#message-counter")
    // const titleInput  = document.querySelector("#kwivrr_alert_configuration_title")
    const titleInput     = document.querySelector('[data-alert-configurations-target=pushTitle]')
    // const messageInput= document.querySelector("#kwivrr_alert_configuration_message")
    const messageInput   = document.querySelector('[data-alert-configurations-target=pushMessage]')

    titleInput.addEventListener('keyup', function(event) {
      remainingCount = titleMax - event.target.value.length

      titleCounter.innerHTML = remainingCount
    });

    messageInput.addEventListener('trix-change', function(event) {
      const inner_text = event.target.innerText
      const inner_html = event.target.innerHTML

      if (inner_text.length > messageMax) {
        if (localStorage["editorState"] !== undefined) {
          event.target.editor.loadJSON(JSON.parse(localStorage["editorState"]))
        }
        toastr.error('Please enter message under 150 characters')
      } else {
        localStorage["editorState"] = JSON.stringify(event.target.editor)
      }

      remainingCount = messageMax - event.target.innerText.length
      messageCounter.innerHTML = remainingCount
    });
  }
}
