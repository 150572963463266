import { Controller } from 'stimulus'; 
export default class extends Controller {

  static targets = ['submitBtn']

  connect() {
    this.submitBtnTarget.addEventListener('click', (event) => {
      const validationResult = this.validateBeforeSubmit();
      if (!validationResult) event.preventDefault();
      return validationResult;
    })
  }

  qtyplus(event){
    const element  = event.target;
    var fieldName  = element.dataset.field;
    var currentVal = parseInt($('input[id='+fieldName+']').val());

    if (!isNaN(currentVal)) {
      $('input[id='+fieldName+']').val(currentVal + 1);
    } else {
      $('input[id='+fieldName+']').val(0);
    }
  }

  qtyminus(event){
    const element  = event.target;
    var fieldName  = element.dataset.field;
    var currentVal = parseInt($('input[id='+fieldName+']').val());

    if (!isNaN(currentVal) && currentVal > 0) {
      $('input[id='+fieldName+']').val(currentVal - 1);
    } else {
      $('input[id='+fieldName+']').val(0);
    }
  }

  validateBeforeSubmit() {
    let formIsValid = true;

    // Check for 'mandatory' Poll(s)
    const pollDropdowns = document.querySelectorAll('[id^="poll_answer_"]');
    pollDropdowns.forEach((pollDropdown) => {
      if (pollDropdown && (pollDropdown.dataset.required == 'true')) {
        if (pollDropdown.value == '') {
          const $pollDDdiv = $(pollDropdown).closest('div.form-group');
          $pollDDdiv.addClass('invalid-form-group');
          $pollDDdiv.find('.form-control').addClass('is-invalid');
          // $pollDDdiv.append(`<div class="invalid-feedback">${i18n.t('field_cannot_be_blank')}</div>`)
          $pollDDdiv.append(`<div class="invalid-feedback">Field cannot be blank</div>`)

          formIsValid = false;
        }
      }
    })

    return formIsValid;
  }
}
