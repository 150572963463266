// all the generic turbo native stimulus utility functions go here.
import { Controller } from 'stimulus'; 

export default class extends Controller {

  appVersion() {
    event.preventDefault()
    event.stopImmediatePropagation()
    window.TurboNativeBridge.postMessage("version")
  }

  signOut(event) {
    console.log('signing out')
    if (this._isTurboNativeApp) {
      event.preventDefault()
      event.stopImmediatePropagation()
      window.TurboNativeBridge.postMessage("signOut")
    }
  }

  showSubnav(event) {
    $('.profile-dropdown').toggle();
  }

  appSwitcher(event) {
    if (this._isTurboNativeApp) {
      console.log('informing Turbo Native App about app_switcher');
      event.preventDefault();
      event.stopImmediatePropagation();
      window.TurboNativeBridge.postMessage("app_switcher", {});
    }
  }

  get _isTurboNativeApp() {
    return (navigator.userAgent.indexOf("Turbo Native") !== -1);
  }
}
