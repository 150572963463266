import { Controller } from 'stimulus'; 
export default class extends Controller {
 
  static targets = ["noOfAds"]

  connect() {   
    this.pagetrack = 1; 
    var no_of_ads = this.noOfAdsTarget.value;
    if (no_of_ads == 1){
      $("#close_ad_modal").show();
    }   
  }

  next_ad(){

    var no_of_ads = this.noOfAdsTarget.value;

    this.pagetrack++;
    $("#previous_ad").show();
    $('[id*="ad_overlay_page_"]').hide()
    $(`#ad_overlay_page_${this.pagetrack}`).show();
    if (this.pagetrack == no_of_ads) {
      $("#next_ad").hide();
      $("#close_ad_modal").show();
    } else{
      $("#next_ad").show();
    }

  }

  previous_ad(){

    this.pagetrack--;
    $("#next_ad").show();
    $('[id*="ad_overlay_page_"]').hide()
    $(`#ad_overlay_page_${this.pagetrack}`).show();
 
    if (this.pagetrack == 1) {
      $("#previous_ad").hide();
    } else{
      $("#previous_ad").show();
    }

  }

  close_modal(){
    $('#ad_overlay_modal').modal('hide');
  }




}


