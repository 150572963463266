// v2 caller: zevents/app/views/v2/kwivrr/advertisements/_form.html.erb

import { Controller } from "stimulus"
import i18n  from "../packs/i18n"


export default class extends Controller {

  connect() {
    $(this.element).modal('show');
    const modalHasLoadedEvent = new CustomEvent('modal-has-loaded');
    window.dispatchEvent(modalHasLoadedEvent);

    // v1
    if (typeof(addFlatPickr) !== 'undefined') {
      addFlatPickr();
    };
  }

  close() {
    var turbo_frame = $(this.element).closest("turbo-frame");
    this.element.remove();
    turbo_frame.empty().removeAttr('src');
  }

  open(event) {
    var link_src = event.target.closest("a").href;
    var modal_src = $('#modal').attr('src');

    if (link_src == modal_src) {
      event.preventDefault();
      $('#turbo_modal').modal('show');
    }
  }

  escClose(event) {
    if (event.key === 'Escape') this.close()
  }

  isClickOutside(event) {
    if (event.target.closest('.modal-dialog')) return;

    this.close();
    var turbo_frame = $('turbo-frame#modal');
    turbo_frame.empty();
    turbo_frame.removeAttr('src');
  }

  handleFileChange(event){
    const allowedExtensions = ['png','gif','jpg','jpeg','bmp'];
    var preview = $("#image-preview");
    var input = $(event.currentTarget);
    var file = input[0].files[0];
    const { name:fileName } = file;
    const fileExtension = fileName.split(".").pop();
    if(allowedExtensions.includes(fileExtension)){
      var reader = new FileReader();
      reader.onload = function(e){
        preview.attr("src", e.target.result);
      };
      reader.readAsDataURL(file);
      $('#no_preview_text').hide('fast');
    }else{
      bootbox.alert(i18n.t('invalid_file_msg'));
    }
  }

  changePicture(e){ 
    e.preventDefault();
    $('#uploadPicture').trigger('click'); 
  };
}
