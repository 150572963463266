import { Controller } from 'stimulus'; 
export default class extends Controller {

  static targets = ['applyPromoCode']
  static values  = { validateCodeUrl: String, orderId: String }

  connect() {
    feather_icons.replace();

    var promo_type = $('input[name="kwivrr_promo[type]"]:checked').val();
    if (promo_type != undefined) {
      this.setPromoTypeToggle(promo_type)
    }
  }

  promoTypeToggle(e) {
    this.setPromoTypeToggle(e.target.value);
  }

  setPromoTypeToggle(value) {
    if (value === 'Kwivrr::DiscountPromo') {
      $(`#discount-wrapper`).slideDown('slow', 'swing');
      $(`#bogo-wrapper`).slideUp('slow', 'swing');
      this.checked_radio_highlight('#kwivrr_promo_type_kwivrrdiscountpromo');
    } else if (value === 'Kwivrr::BogoPromo') {
      $(`#discount-wrapper`).slideUp('slow', 'swing');
      $(`#bogo-wrapper`).slideDown('slow', 'swing');
      this.checked_radio_highlight('#kwivrr_promo_type_kwivrrbogopromo');
    }
  }

  checked_radio_highlight(element) {
    $(element).parents('.radio_buttons').children('.form-check').find('label').removeClass('checked-radio')
    if($(element).prop('checked')) {
      $(element).closest('div.form-check').find('label').addClass('checked-radio');
    }
  }

  changeDiscountType(e) {
    var label_text

    if (e.target.value == 'fixed_amount') {
      label_text = 'PROMO Amount'
    } else if (e.target.value == 'percentage') {
      label_text = 'PROMO Percentage'
    }

    $('#discount-value-label').text(label_text)
  }

  changeBogoDiscountType(e) {
    var label_text

    if (e.target.value == 'get_ticket') {
      $('#bogo-discount-amount-wrapper').addClass('hide');
      $('#bogo-get-ticket-wrapper').removeClass('hide');
    } else {
      $('#bogo-get-ticket-wrapper').addClass('hide');
      $('#bogo-discount-amount-wrapper').removeClass('hide');

      if (e.target.value == 'fixed_amount') {
        label_text = 'PROMO Amount'
      } else if (e.target.value == 'percentage') {
        label_text = 'PROMO Percentage'
      }

      $('#bogo-discount-value-label').text(label_text)
    }
  }

  checkPromo() {
    var nullValues = ['', null, undefined]
    var code       = $('#promo-code-input').val();

    if (nullValues.includes(code)) {
      toastr.error("Please enter valid code..")
    } else {
      this.validatePromo(code)
    }
  }

  async validatePromo(code) {
    var codeIsValid

    let params = new URLSearchParams();
    params.append("order_id", this.orderIdValue);
    params.append("code", code);

    await fetch(`${this.validateCodeUrlValue}?${params}`)
            .then(response => response.json())
              .then(data => {
                if (data.success) {
                  codeIsValid = true
                } else {
                  codeIsValid = false
                  toastr.error(data.message)
                }
              })

    console.log(codeIsValid)
    if (codeIsValid) {
      this.applyPromo(code)
    }
  }

  applyPromo(code) {
    var params         = `?code=${code}`;
    var applyPromoLink = $(this.applyPromoCodeTarget);
    var url            = applyPromoLink.attr('href');

    url = url + params;
    applyPromoLink.attr('href', url);
    applyPromoLink[0].click();
  }
}
