import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ["source"]

  connect() {
    console.log("clipboard connect");
  }

  copy(event) {
    if (navigator.clipboard && window.isSecureContext) {
      // https
      var copyText = this.sourceTarget.value;
      console.log("clipboard: ", copyText);
      navigator.clipboard.writeText(copyText);
    } else {
      // http (dev)
      this.sourceTarget.select();
      this.sourceTarget.focus();
      console.log("copy");
      document.execCommand("copy");
    }
  }
}