import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['modalElement'];
  static values = { url: String }

  connect() {
    $(this.modalElementTarget).on('hidden.bs.modal', () => Turbo.visit(this.urlValue));
  }

  handleClose() {
    Turbo.visit(this.urlValue);
  }
}