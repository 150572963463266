import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ "back", "next", "save", "step", "stepNumber", "pollType",
    "singleOption", "multipleOptions", "textOption", "stepIndicator", "question", "pollAnswer",
    "singleOptionPollAnswerContainer", "multipleOptionsPollAnswerContainer",
    "recipient", "deliveryMethod", "mandatory"
  ];
  static values = { currentStep: Number, numOfSteps: Number, pollType: String, question: String, answers: Array, 
    deliveryMethods: Array, listOfAllDeliverNowMethods: Array, listOfAllDeliverLaterMethods: Array, recipients: Object,
    doNotChangeDeliveryMethods: Boolean, answerDebouncer: Number
  };

  connect() {
    console.log(`pollTypeValue = ${this.data.get("pollTypeValue")}`);
    console.log(`answersValue = ${this.data.get("answersValue")}`);
    console.log(`deliveryMethodsValue = ${this.data.get("deliveryMethodsValue")}`);
    console.log(`recipientsValue = ${this.data.get("recipientsValue")}`);
  }
  
  initialize() {
    this.currentStepValue = 1;
    this.listOfAllDeliverNowMethodsValue = ['email', 'web_alert', 'text']; // These come from poll_delivery_method.rb
    this.listOfAllDeliverLaterMethodsValue = ['checkout', 'in_event'];     // These come from poll_delivery_method.rb
    this.clearRecipientsValue();
  }

  get cssClassForSelected() {
    return this.data.get("cssClassForSelected");
  }

  next() {
    this.currentStepValue = Math.min(this.currentStepValue + 1, this.numOfStepsValue);
  }

  back() {
    this.currentStepValue = Math.max(this.currentStepValue - 1, 1);
  }

  currentStepValueChanged() {
    console.log(`currentStep = ${this.currentStepValue}`);

    if (this.currentStepValue == 3) {
      if (!this.questionValue) {
        this.currentStepValue -= 1;
        console.log(`currentStep - send back to = ${this.currentStepValue}`);
        bootbox.alert('Please enter your Question for Survey');
      }

      if (['single_option', 'multiple_options'].includes(this.pollTypeValue)) {
        if (this.answersValue.length <=1) {
          this.currentStepValue -= 1;
          console.log(`currentStep - send back to = ${this.currentStepValue}`);
          bootbox.alert('You must specify Answers for all the Boxes. Minimum needed are 1 & 2. You can remove 3 & 4.');
        }
      }
    }
    this.showCurrentStep();
  }

  showCurrentStep() {
    // Show contents of Current Step
    this.stepTargets.forEach((element, index) => {
      element.hidden = !(index+1 == this.currentStepValue);
    })

    // Show correct buttons at the bottom
    this.backTarget.hidden = (this.currentStepValue == 1);                     // BACK button is hiddden on 1st step
    this.nextTarget.hidden = this.inLastStep();  // NEXT button is hidden on last step
    this.saveTarget.hidden = !this.inLastStep();  // SAVE button is hidden on all steps except last

    // At each step, disable Next/Save button if value is not selected
    if (this.currentStepValue == 1) {
      this.nextTarget.disabled = !this.pollTypeValue;
    }
    if (this.currentStepValue == 2) {
      this.enableDisableInStep2();
    }
    if (this.currentStepValue == 3) {
      this.enableDisableSaveBtnInStep3();
    }

    // Update label and indicator at the bottom
    this.stepNumberTarget.innerText = this.currentStepValue.toString();
    this.stepIndicatorTargets.forEach((indicator, index) => {
      if (index + 1 > this.currentStepValue) {
        indicator.classList.remove('step-indicator-border-reached');
        indicator.classList.add('step-indicator-border-not-reached');
      } else {
        indicator.classList.remove('step-indicator-border-not-reached');
        indicator.classList.add('step-indicator-border-reached');
      }
    })
  }

  selectPollType(e) {
    e.preventDefault();
    const clickedElement = e.target;
    const selectedPollType = clickedElement.dataset.pollType;
    this.pollTypeTargets.filter(t => t != clickedElement).forEach(other => {
      other.classList.remove(this.cssClassForSelected);
    })
    clickedElement.classList.add(this.cssClassForSelected);

    // Update stimulus backing value
    this.pollTypeValue = selectedPollType;

    // Enable NEXT button
    this.nextTarget.disabled = false;

    // Show correct set of Answer options in Step 2
    [this.singleOptionTarget, this.multipleOptionsTarget, this.textOptionTarget].forEach(x => {
      x.hidden = (x.dataset.pollType != selectedPollType);
    })
  }

  pollTypeValueChanged() {
    console.log(`pollTypeValueChanged to ${this.pollTypeValue}`);

    // If pollType changes, then we need to reinitialize everything and start over again
    this.questionValue = "";
    this.answersValue = [];
    if (!this.doNotChangeDeliveryMethodsValue) {
      this.deliveryMethodsValue = [];
    }
    this.clearRecipientsValue();
    // Clear backing stimulus fields ?
    // Clear hidden form values ?
  }

  removeAnswer(event) {
    const ele = event.target;
    const container = $(ele).closest('[class="poll-answer-container"]');
    container.get(0).hidden = true;
    container.find('input[type=text]').val('');
  }

  questionKeyPressedInStep2(event) {
    this.questionValue = event.target.value;
  }

  questionValueChanged() {
    this.enableDisableInStep2();
  }

  answerKeyPressedInStep2(event) {
    // Below we are debouncing the code because we have a lot of code running as the user types each character
    if (this.answerDebouncerValue) window.clearTimeout(this.answerDebouncerValue);

    this.answerDebouncerValue = window.setTimeout(() => {
      let inputTextBoxContainers = [];
      this.answersValue = [];
      if (['single_option', 'multiple_options'].includes(this.pollTypeValue)) {
        if (this.pollTypeValue == 'single_option') {
          inputTextBoxContainers = this.singleOptionPollAnswerContainerTargets.filter(x => !x.hidden);
        }
        if (this.pollTypeValue == 'multiple_options') {
          inputTextBoxContainers = this.multipleOptionsPollAnswerContainerTargets.filter(x => !x.hidden);
        }

        inputTextBoxContainers.forEach(container => {
          const txt = container.querySelector('input[type=text]');
          if (txt.value) {
            this.answersValue = [...this.answersValue, txt.value];
          }
        })
      }
    }, 250);
  }

  answersValueChanged() {
    this.enableDisableInStep2();
  }

  selectDeliveryMethod(event) {
    const ele = event.target;

    const dm = ele.dataset.deliveryMethod;
    if (this.deliveryMethodsValue.includes(dm)) {
      this.deliveryMethodsValue = this.deliveryMethodsValue.filter(x => x != dm);
    } else {
      this.deliveryMethodsValue = [...this.deliveryMethodsValue, dm];
    }

    // If a 'Deliver Now' method is selected, we need to deselect 'Deliver Later' methods.
    // If a 'Deliver Later' method is selected, we need to deselect 'Deliver Now' methods.
    const dmType = ele.dataset.deliveryMethodType;
    if (dmType == "deliver_now") {
      this.deliveryMethodsValue = this.deliveryMethodsValue.filter(x => !this.listOfAllDeliverLaterMethodsValue.includes(x)); 
    } else if (dmType == "deliver_later") {
      this.deliveryMethodsValue = this.deliveryMethodsValue.filter(x => !this.listOfAllDeliverNowMethodsValue.includes(x));
    }

    console.log(`this.deliveryMethodsValue = ${this.deliveryMethodsValue}`);

    // If a 'Deliver Later' method is selected, we need to delesect 'Recipients'
    // because 'Recipients' does not apply for 'Deliver Later'
    if (dmType == "deliver_later") {
      this.clearRecipientsValue();
      document.querySelector('.poll-recipients-container').style.opacity = 0.3;
      // document.querySelector('.poll-recipients-container').style.display = 'none';
      this.recipientTargets.forEach(x => x.disabled = true);
    } else {
      document.querySelector('.poll-recipients-container').style.opacity = 1;
      // document.querySelector('.poll-recipients-container').style.display = '';
      this.recipientTargets.forEach(x => x.disabled = false);
    }
  }

  deliveryMethodsValueChanged() {
    if (!this.deliveryMethodTargets || !this.hasDeliveryMethodsValue) return;

    this.deliveryMethodTargets.forEach(ele => {
      if (this.deliveryMethodsValue.includes(ele.dataset.deliveryMethod)) {
        ele.classList.add(this.cssClassForSelected);
      } else {
        ele.classList.remove(this.cssClassForSelected);
      }
    })

    this.enableDisableSaveBtnInStep3();
  }

  selectRecipient(event) {
    const ele = event.target;
    if (ele.dataset.recipient == "attending") {
      this.recipientsValue = { ...this.recipientsValue, attending: !this.recipientsValue.attending };  
    } else if (ele.dataset.recipient == "checked_in") {
      this.recipientsValue = { ...this.recipientsValue, checked_in: !this.recipientsValue.checked_in };
    } else if (ele.dataset.recipient == "interested") {
      this.recipientsValue = { ...this.recipientsValue, interested: !this.recipientsValue.interested };
    } else if (ele.dataset.isRecipientTicketType) {
      let arr = this.recipientsValue.ticket_type_ids;
      const tktTypeId = ele.dataset.ticketTypeId;
      arr = arr.includes(tktTypeId) ? arr.filter(x => x != tktTypeId) : [...arr, tktTypeId];
      this.recipientsValue = { ...this.recipientsValue, ticket_type_ids: arr };
    }

    console.log(`this.recipientsValue = ${JSON.stringify(this.recipientsValue)}`);
  }

  recipientsValueChanged() {
    if (!this.recipientTargets || !this.hasRecipientsValue) return;

    this.recipientTargets.forEach(ele => {
      let result = false;
      if (ele.dataset.recipient == "attending") {
        result = this.recipientsValue.attending;
      } else if (ele.dataset.recipient == "checked_in") {
        result = this.recipientsValue.checked_in;
      } else if (ele.dataset.recipient == "interested") {
        result = this.recipientsValue.interested;
      } else if (ele.dataset.isRecipientTicketType) {
        result = this.recipientsValue.ticket_type_ids.includes(ele.dataset.ticketTypeId);
      } 

      result ? ele.classList.add(this.cssClassForSelected) : ele.classList.remove(this.cssClassForSelected);
    })

    this.enableDisableSaveBtnInStep3();
  }

  clearRecipientsValue() {
    this.recipientsValue = { "attending": false, "checked_in": false, "interested": false, ticket_type_ids: [] };
  }
  
  enableDisableInStep2() {
    let result = false;
    if (['single_option', 'multiple_options'].includes(this.pollTypeValue)) {
      result = (this.questionValue && (this.answersValue.length >= 2));
    } else if(this.pollTypeValue == 'text') {
      result = (this.questionValue ? true : false);
    }

    // Step 2 can be the last step 
    // (for e.g. when user creates an IN_EVENT poll from /host_connect page, we do not need Step 3,
    // because we already know the Delivery Method and the Recipients list)
    if (this.inLastStep()) {
      this.saveTarget.disabled = !result;
    } else {
      this.nextTarget.disabled = !result;
    }
  }
  
  enableDisableSaveBtnInStep3() {
    if (!this.hasSaveTarget) return;

    // To enable the Save button:
    // If 'Deliver Now' method is selected, we need at least 1 Recipient to be selected
    // If 'Deliver Later' method is selected, we will not show Recipients at all
    this.saveTarget.disabled = true;
    const deliverLaterSelected = (this.deliveryMethodsValue.filter(x => this.listOfAllDeliverLaterMethodsValue.includes(x)).length > 0);
    const deliverNowSelected = (this.deliveryMethodsValue.filter(x => this.listOfAllDeliverNowMethodsValue.includes(x)).length > 0);
    if (deliverLaterSelected) {
      this.saveTarget.disabled = false;
    } else if (deliverNowSelected) {
      if (this.recipientsValue.checked_in || this.recipientsValue.attending || this.recipientsValue.interested || (this.recipientsValue.ticket_type_ids.length > 0)) {
        this.saveTarget.disabled = false;
      }
    }
  }

  inLastStep() {
    return (this.currentStepValue == this.numOfStepsValue);
  }

  addAnotherAnswerToSingleOptionPoll(event) {
    const ele = event.target;
    const form = ele.closest('form');
    form.querySelector('[name="ans_num"]').value = this.singleOptionPollAnswerContainerTargets.length + 1;
    Turbo.navigator.submitForm(form);
  }

  addAnotherAnswerToMultipleOptionsPoll(event) {
    const ele = event.target;
    const form = ele.closest('form');
    form.querySelector('[name="ans_num"]').value = this.multipleOptionsPollAnswerContainerTargets.length + 1;
    Turbo.navigator.submitForm(form);
  }

  save() {
    // Populate form and submit it
    document.querySelector('input[name="kwivrr_poll[poll_type]"]').value = this.pollTypeValue;
    document.querySelector('input[name="kwivrr_poll[question]"]').value = this.questionValue;
    document.querySelector('input[name="poll_delivery_methods"]').value = JSON.stringify(this.deliveryMethodsValue);
    document.querySelector('input[name="poll_options"]').value = JSON.stringify(this.answersValue);
    document.querySelector('input[name="kwivrr_poll[recipients]"]').value = JSON.stringify(this.recipientsValue);
    document.querySelector('input[name="kwivrr_poll[mandatory]"]').value = document.querySelector('#mandatory').checked;
    const form = document.querySelector('input[name="kwivrr_poll[poll_type]"]').closest('form');
    Turbo.navigator.submitForm(form);
  }
}

// <input type="hidden" value="some answer" name="kwivrr_poll[poll_options_attributes][0][option]" id="kwivrr_poll_poll_options_attributes_0_option">
// <input type="hidden" value="email" name="kwivrr_poll[poll_delivery_methods_attributes][0][delivery_method]" id="kwivrr_poll_poll_delivery_methods_attributes_0_delivery_method">