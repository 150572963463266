import { Controller } from 'stimulus';
export default class extends Controller {
  static targets = ["resizeImg"] // img elements

  connect() {
    console.log("image controller connect");
    // https://dev.to/leastbad/the-best-one-line-stimulus-power-move-2o90
    var controller_label = 'controller_' + this.identifier; // controller_image
    this.element[controller_label] = this; // wrapper (e.g. div) element
  }

  resizeImage(element) {
    // console.log("resizeImage: ", element);
    if (element.width > element.height) {
      element.classList.add("landscape-thumbnail")
    } else if(element.height > element.width) {
      element.classList.add("portrait-thumbnail");
    } else {
      element.classList.add("square-thumbnail");
    };
    element.parentNode.classList.add("image_flex_container");
  }

  resizeImages(options = {}) {
    console.log("resizeImages: ", options.debug);
    this.resizeImgTargets.forEach((element) => {
      this.resizeImage(element)
    });
  }

  // https://world.hey.com/hotwired/stimulus-3-c438d432
  resizeImgTargetConnected(element) {
    // console.log("resizeImgTargetConnected: ", element);
    this.resizeImage(element)
  }
};