import { Controller } from 'stimulus'; 
export default class extends Controller {
  static targets = ["memberId"];


  mute_member(e){
    var ele = this; 
    var member_id = this.memberIdTarget.value;

    bootbox.confirm("Are you sure you want to mute this user", function(confirmed) {
        if (confirmed) {
          $.get(`/v2/event_room_members/${member_id}/mute_member?mute=true`);   

          $("#unmute_member_"+member_id).show();
          $("#mute_member_"+member_id).hide();
        }
    });

  }

  unmute_member(e){

    var ele = this;
    console.log(ele);
    var member_id = this.memberIdTarget.value;

    bootbox.confirm("Are you sure you want to unmute this user", function(confirmed) {
        if (confirmed) {
          $.get(`/v2/event_room_members/${member_id}/mute_member?mute=false`);  
          $("#unmute_member_"+member_id).hide();
          $("#mute_member_"+member_id).show();       

        
        }
    });

  }
     


}


