import { Controller } from "stimulus";

export default class extends Controller {
  static values = { url: String }

  connect() {
    console.log("redirect connected");
    Turbo.visit(this.urlValue);
  }
}
