// http://localhost:3000/v2/reports/attendee_history

import { Controller } from 'stimulus'; 
export default class extends Controller {
  static targets = [
    "flatpickr_datetime",
    "flatpickr_time", // unused in v2
    "flatpickr" // unused in v2
  ]

  connect() {
    console.log("flatpickr controller connect");
    this["flatpickr_controller"] = this;
  }

  // https://world.hey.com/hotwired/stimulus-3-c438d432
  flatpickr_datetimeTargetConnected(element) {
    this.load_flatpickr(element);

    var func1 = this.load_flatpickr;
    element['reload'] = function() { func1(element); }
  }

  load_flatpickr(element) {
    // console.log("load_flatpickr: ", element);

    var options = {
      altInput: true,
      enableTime: true,
      altFormat: "F j, Y h:i K",
      dateFormat: "Y-m-d h:i K"
    };

    // reload
    var el_val = element.value;
    if (el_val) {
      options.defaultDate = el_val;
    }

    // console.log("options", options);
    element.flatpickr(options);
  }
}
