import { Controller } from 'stimulus';
import i18n from "../packs/i18n"

var interval;
export default class extends Controller {
  static targets = [
    "streamUrl",
    "noOfAds",
    "uploadPicture",

    "speakersWrapper",
    "scheduleTicketingWrapper",
    "eventDetailsWrapper",
    "advancedWrapper",
    "fieldsWrapper",

    "speakersTitleChevron",
    "scheduleTicketingChevron",
    "eventDetailsTitleChevron",
    "advancedTitleChevron",

    "kwivrrStreaming",
    "kwivrrMeeting",
    "kwivrrMeetingDesc",
    "kwivrrStreamingDesc",
    "btnClearProvider",
    "btnKwivrrProviderToggleSave",

    "providerRadioButton",
    "eventTypeToggleButton",

    "breakoutTitleChevron"
  ]

  static values = {
    getEmbedUrl: String,
    payoutOptionsUrl: String,
    payoutUpdateUrl: String,
    payoutMethodId: String,
    eventId: String,
    imageType: String,
    livestreamType: String,
    futureEvent: Boolean,
    eventStartDate: String,
    timerShow: String,
    ticketTransferMsgShow: String,
    providerType: String,
    reloadPickr: String,
    isEventPersisted: Boolean,
    needzoomauth: String,
    authUrl: String
  }

  disconnect() {
    window.clearInterval(interval);
  }

  // TODO: clean this up.
  connect() {
    console.log("events connect");

    if(this.providerTypeValue){
      console.log(`Provider Type is already set to ${this.providerTypeValue}`)
      this.providerToggle(this.providerTypeValue,false);
      this.setProvider(this.providerTypeValue);
      $('#clear_provider_selection').show();
      $('.zevents_event_provider_provider_type').addClass('full-width');
    }
    
    if(this.reloadPickrValue == "true"){
      this.reload_flatpickr()
    }

    this.set_livestream_type_toggle(this.livestreamTypeValue)
    
    if(this.ticketTransferMsgShowValue && this.ticketTransferMsgShowValue == "true"){
      bootbox.dialog({
        message: "<i data-feather='corner-up-right' style='color: #0000ff;'></i>  <b>"+i18n.t('transfer_msg_1')+"</b>&nbsp&nbsp&nbsp"+i18n.t('transfer_msg_2')
      });
    }


    if(this.timerShowValue && this.futureEventValue){ this.set_event_count_down(this.eventStartDateValue) }

    var paid_event = $('#zevents_event_paid_event').val();
    if (paid_event == "true") {
      setTimeout(function () {
        $("#ticketCheckbox").prop('checked', true);
        $('#fieldsWrapper').slideDown('fast');
      }, 50);
    }

    // Adding opacity to providers
    $('.zevents_event_provider_provider_type div.form-check').addClass('opacity_nan'); 

    var stream_type = $('input[name="zevents_event[event_type]"]:checked').val();
    if (stream_type != undefined) {
      this.set_stream_type_toggle(stream_type)
    }

    $('select').selectpicker();

    $('input[id=zevents_event_total_capacity]').on('change', function () {
      if ($(this).val() < 0 || $(this).val() == '') {
        $(this).val('0');
      }
    });

    $('input[id=zevents_event_vip_capacity]').on('change', function () {
      if ($(this).val() < 0 || $(this).val() == '') {
        $(this).val('0');
      }
    });

    $('input[id=zevents_event_general_ticket_price]').on('change', function () {
      if ($(this).val() < 0 || $(this).val() == '') {
        $(this).val('0.00');
      }
    });

    $('input[id=zevents_event_vip_ticket_price]').on('change', function () {
      if ($(this).val() < 0 || $(this).val() == '') {
        $(this).val('0.00');
      }
    });


    $('.register_event').on("click",function(){
      $(window).scrollTop(10);
    });


    $(".zevents_event_provider_provider_type input[type='radio'], #zevents_event_general_ticket_price, #zevents_event_vip_ticket_price, .zevents_event_event_type").on('change', function () {
      var event_type = $('input[name="zevents_event[event_type]"]:checked').val();
      if (event_type == "digital") {
        if (($(".zevents_event_provider_provider_type input[type='radio']:checked").val() == 'YouTube')) {
          if (($('#zevents_event_general_ticket_price').val() > 0) ||
            ($('#zevents_event_vip_ticket_price').val() > 0)) {
            $('#ticket_sale_provider_notice').removeClass('d-none');
          } else {
            $('#ticket_sale_provider_notice').addClass('d-none');
          }
        }
      } else {
        $('#ticket_sale_provider_notice').addClass('d-none');
      }
    });

    $('input[type=radio]').each(function(){
      $(this).next('label').detach().insertBefore(this);
    });
  }

  // TODO: use this for processes that can run _once_
  initialize() {
    console.log("events controller initialize");

    var proxy_params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    if (proxy_params.event_type == 'digital') {
      // if (proxy_params.provider_type !== 'jitsi' && proxy_params.provider_type !== 'red5') {
      //   document.getElementById("zevents_event_provider_attributes_provider_type_kwivrr").click();
      // }
    }
  }

  after_submission(event) {
    console.log("after_submission");
    var livestream_type = $('#zevents_event_livestream_type').val();
    if (event.detail.success == true) {
      if (this.noOfAdsTarget.value >= 1) {
        setTimeout(function () {
          $('#ad_overlay_modal').modal({
            backdrop: 'static'
          });
        }, 500);
      }

    }
    if (event.detail.success == false) {
      var paid_event = $('#zevents_event_paid_event').val();
      if (paid_event == "true") {
        setTimeout(function () {
          $("#ticketCheckbox").prop('checked', true);
          $('#fieldsWrapper').slideDown('fast');
        }, 50);
      }
    }
  }

  trainer_profile_modal(e) {
    var link = e.currentTarget;
    var speaker_profile_attr_id = ($(link).closest('.fields').closestChild('select')).attr("id");
    //  $.get(`/trainer_profile/new`);
    $("#speaker_profile_attr_id").val(speaker_profile_attr_id);
    $('#trainer_create_modal').modal('show');
  }

  changeSpeakerPicture(e) {
    e.preventDefault();
    var rank_id = $(e.currentTarget).data('rand-id')
    $(`#speakerPictureUpload-${rank_id}`).trigger('click');
  }

  handleSpeakerPicUpload(event){
    var input      = $(event.currentTarget);
    var preview    = $("#" + input.data('previewId'));
    var file       = input[0].files[0];
    var reader     = new FileReader();

    reader.onload  = function(e){
      preview.attr('src', e.target.result);
    };

    reader.readAsDataURL(file);
  }

  // "Start Now"/"Schedule For Later"
  stream_type_toggle(e) {
    this.set_livestream_type_toggle(e.target.value)
    this.set_stream_type_toggle(e.target.value)

    if (e.target.id == "zevents_event_event_type_digital") {
      // console.log("zevents_event_event_type_digital selected");
      // document.getElementById("zevents_event_provider_attributes_provider_type_kwivrr").click();
    };
  }

  // :stream_type_toggle
  // :connect
  set_stream_type_toggle(value) {
    // console.log("set_stream_type_toggle");

    if (value == 'digital') {
      $('#providerWrapper').slideDown('slow', 'swing');
      $('.zevents_event_shop_url').css('display', 'block');
      $('#event_location').slideUp('slow', 'swing');
      this.checked_radio_highlight('#zevents_event_event_type_digital');
    } else if (value == 'physical') {
      $('#providerWrapper').slideUp('slow', 'swing');
      $('.zevents_event_shop_url').css('display', 'none');
      $('#event_location').slideDown('slow', 'swing');
      this.checked_radio_highlight('#zevents_event_event_type_physical');
    }

    this.triggerResizeImages();
  }

  set_livestream_type_toggle(value){
    if (value == 'in_future') {
      $("#zevents_event_livestream_type_in_future").prop('checked', true);
      $('.event-time-section').show('fast');
      $("#event-create").val(this.isEventPersistedValue ? i18n.t('events.form.save') : i18n.t('events.form.create')); // "CREATE" or "SAVE"
      this.checked_radio_highlight('#zevents_event_livestream_type_in_future')
    } else if(value == 'is_immediate') {
      $('.event-time-section').hide('fast');
      $("#zevents_event_livestream_type_is_immediate").prop('checked', true);
      $("#event-create").val(this.isEventPersistedValue ? i18n.t('events.form.save') : i18n.t('events.form.go_live')); // "GO LIVE" or "SAVE"
      this.checked_radio_highlight('#zevents_event_livestream_type_is_immediate');
    }
  }

  checked_radio_highlight(element) {
    $(element).parents('.radio_buttons').children('.form-check').find('label').removeClass('checked-radio')
    if($(element).prop('checked')) {
      $(element).closest('div.form-check').find('label').addClass('checked-radio');
    }
  }

  get_embed_code_and_thumb() {
    var provider = $('input[name="zevents_event[provider_attributes][provider_type]"]:checked').val();
    var stream_url = this.streamUrlTarget.value;
    var isValidUrl = this.validURL(stream_url);

    if (isValidUrl) {
      $.ajax({
        url: this.getEmbedUrlValue,
        data: {
          provider: provider,
          stream_link: stream_url
        },
        success: function (result) {

        }
      });
    } // isValidUrl check end
  }

  validURL(str) {
    var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return !!pattern.test(str);
  }

  load_payout_options(event) {
    event.preventDefault();
    let value = this.payoutMethodIdValue;
    let event_id = this.eventIdValue;
    let updateUrl = this.payoutUpdateUrlValue;
    if (!value) { value = '0' }
    $.getJSON(this.payoutOptionsUrlValue)
      .done(function (data) {
        bootbox.prompt({
          title: "Select One",
          value: value,
          event_id: event_id,
          inputType: 'select',
          updateUrl: updateUrl,
          inputOptions: data,
          callback: function (result) {
            if (result) {
              console.log(updateUrl)
              $.ajax({
                url: updateUrl,
                data: { id: result, event_id: event_id },
                type: "GET",
                success: function (data) {
                  $("#payout_banking_info_" + event_id).html(data.html);
                  $("#payout_banking_info").html(data.html);
                  $('#zevents_event_payout_method_id').val(result);
                }
              })
            }
          }
        });
      });
  }

  handleFileChange(event) {
    const allowedExtensions = ['png', 'gif', 'jpg', 'jpeg', 'bmp'];
    if (this.imageTypeValue == 'event_image') {
      var preview = $("#image-preview");
    }
    var input = $(event.currentTarget);
    var file = input[0].files[0];
    const { name: fileName } = file;
    const fileExtension = fileName.split(".").pop();
    if (allowedExtensions.includes(fileExtension)) {
      var reader = new FileReader();
      reader.onload = function (e) {
        preview.attr("src", e.target.result);
      };
      reader.readAsDataURL(file);
      $('#no_preview_text').hide('fast');
    } else {
      bootbox.alert(i18n.t('invalid_file_msg'));
    }
  }

  clearText(event) {
    var input = $(event.currentTarget);
    input[0].value = '';
  }

  providerTypeToggle(event) {
    // console.log("providerTypeToggle");
    // console.log("this.authUrlValue", this.authUrlValue);
    // console.log("this.needzoomauthValue", this.needzoomauthValue);

    this.providerToggle(event.target.value,true)
    var value = event.target.value;
    if(value != 'Kwivrr' && value != 'Jitsi') {
      this.setProvider(value);
    }

    $('#zevents_event_provider_attributes_embed_code').val('');

    if ( this.value == "Zoom") {
      $('.zevents_event_provider_embed_code').addClass('d-none');
      if (this.needzoomauthValue == "true") {
        var auth_url = this.authUrlValue;
        auth_url = auth_url + '&state=' + window.location.href;
        auth_url = auth_url.replace(/&amp;/g,'&');
        auth_window = window.open(auth_url, 'zoom_auth_window', 'width=800, height=600, scrollbars=yes, resizable=yes');
      }
    } else if (this.value == "Jitsi" || this.value == "Kwivrr") { 
      $('.zevents_event_provider_embed_code').addClass('d-none');
    } else {
      $('.zevents_event_provider_embed_code').removeClass('d-none');
    }
  }

  providerToggle(value,modal) {
    if(value == 'Kwivrr' || value == 'Jitsi') {
      $('.zevents_event_provider_embed_code').addClass('d-none');
      if(modal){
        $('#kwivrr-stream-selector').modal('show');
        var selected_provider = $('input[name="kwivrr_provider"]:checked').val();
        if (selected_provider) {
          document.querySelector('#btnKwivrrProviderToggleSave').disabled = false;
        } else {
          document.querySelector('#btnKwivrrProviderToggleSave').disabled = true;
        }
      }
    }
  }

  kwivrrProviderToggleSave(event) {
    var selected_provider = $('input[name="kwivrr_provider"]:checked').val();
    if (selected_provider) {
      $('#zevents_event_provider_attributes_provider_type_kwivrr').val(selected_provider);
      console.log(`selected_provider = ${selected_provider}`);
      this.setProvider(selected_provider);
      $('#kwivrr-stream-selector').modal('hide');
    } else {
      bootbox.alert(i18n.t('events.form.please_select_an_option'));
    }
  }

  chargeTaxToggle(event) {
    $('.custom_tax-fields').toggleClass('hide');
  }

  setProvider(value) {
    // Dev Notes: data-provider-type will be present only after User selection.
    // If data-provider-type does not exist, we want to show all the options.
    // If data-provider-types exists, then we need to hide all options except the selected one.
    // Hiding is being done in CSS.
    if (value) {
      console.log(`settting data-provider-type to ${value}`);
      $('input[type=radio][id^=zevents_event_provider_attributes_provider_type]').attr('data-provider-type', value);
      $('#clear_provider_selection').show();
      $('.zevents_event_provider_provider_type').addClass('full-width');
    }
  }

  clearProvider() {
    console.log('clearing data-provider-type');
    $('input[type=radio][id^=zevents_event_provider_attributes_provider_type]').removeAttr('data-provider-type');
  }

  clearProviderSelection(event) {
    event.preventDefault();
    // $('#zevents_event_provider_attributes_provider_type_kwivrr').val('Kwivrr');
    $('#kwivrr-stream-selector').modal('hide');
    $('.zevents_event_provider_provider_type .form-check').show();
    $('.zevents_event_provider_provider_type').removeClass('full-width');
    $('#clear_provider_selection').hide();
    $('#kwivrr_webinar_label, #kwivrr_collaboration_label').removeClass('checked-radio');
    this.hideJitsiOption();
    this.clearProvider();
  }

  hideJitsiOption() {
    $('#zevents_event_provider_attributes_provider_type_jitsi').closest('.form-check').hide();
  }

  clearKwivrrProviderSelection(){
    $('.kwivrr-streaming').removeClass('selected not-selected');
    $('.kwivrr-meeting').removeClass('selected not-selected');
    $('.kwivrr-meeting-desc').removeClass('d-none').addClass('d-flex');
    $('.kwivrr-streaming-desc').removeClass('d-none').addClass('d-flex');
    $('#btnClearProvider').addClass('d-none');
    document.querySelector('input[name="kwivrr_provider"]:checked').checked = false;
    document.querySelector('#btnKwivrrProviderToggleSave').disabled = true;
    this.clearProvider();
  }

  set_event_count_down(event_start_date) {
   
    interval = setInterval(function() {
      // Find the distance between now and the count down date
      var distance = (event_start_date - new Date().getTime());
      // Time calculations for days, hours, minutes and seconds
      var days = Math.floor(distance / (1000 * 60 * 60 * 24));
      var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);  
      hours  = ('0' + hours).slice(-2);
      minutes = ('0' + minutes).slice(-2);
      seconds = ('0' + seconds).slice(-2); 
      $("#countdown_timer").html("<div></div>  " + "<div><span>" + days + "<small>Days</small></span></div>" + "<div><span>" + hours + "<small>Hours</small></span></div>" + "<div><span>" + minutes + "<small>Minutes</small></span></div>" + "<div><span>" + seconds + "<small>Seconds</small></span></div>");
      if (distance < 0) {
        clearInterval(interval);
        $("#countdown_timer").html("");
        Turbo.visit(window.location.href); // Reload the current page when timer finishes
      }
    }, 1000);
  }

  // TODO: move to flatpickr_controller.js
  // TODO: is this really necessary?
  reload_flatpickr(querystr = "") {
    var basequerystr = "[data-flatpickr-target='flatpickr_datetime']"
    if (querystr.length > 0) {
      var fullquerystr = querystr + " " + basequerystr;
    } else {
      var fullquerystr = basequerystr;
    }

    $(fullquerystr).each(function(index,item) {
      item.reload();
    });
  }

  advancedTitleClick() {
    // console.log("advancedTitleClick");
    $(this.advancedWrapperTarget).slideToggle('fast', function() {});
    this.advancedTitleChevronTarget.classList.toggle("arrow-up");

    this.triggerResizeImages();    
  }

  triggerResizeImages() {
    // https://stimulus.hotwired.dev/reference/controllers#directly-invoking-other-controllers
    var firstImg = document.querySelector('[data-controller="image"]');
    if (firstImg) {
      firstImg.controller_image.resizeImages({ debug: "events triggerResizeImages" });
    }
  }

  // "Add Speaker" link for adding nested :event_agenda
  // Show sub-form.
  addSpeakerLinkClick(event) {
    // console.log("addSpeakerLinkClick");

    // TODO: can this be more specific?
    $('select').selectpicker();

    const ele          = $(event.currentTarget);
    const container_id = ele.data('association');
    const container    = `#${container_id} div.fields:last`

    $(container || 'body').find("[data-flatpickr-target='flatpickr_datetime']").flatpickr({
      altInput:   true,
      enableTime: true,
      altFormat:  "F j, Y h:i K",
      dateFormat: "Y-m-d h:i K"
    });
  }

  coverImageIconClick(event) {
    // console.log("coverImageIconClick");
    this.uploadPictureTarget.click();
  }

  speakersTitleClick(event) {
    // console.log("speakersTitleClick");
    $(this.speakersWrapperTarget).slideToggle('fast');
    this.speakersTitleChevronTarget.classList.toggle("arrow-up");
  }

  scheduleTicketingClick(event) {
    // console.log("scheduleTicketingClick");
    $(this.scheduleTicketingWrapperTarget).slideToggle('fast');
    this.scheduleTicketingChevronTarget.classList.toggle("arrow-up");
  }

  ticketCheckboxChange(event) {
    // console.log("ticketCheckboxChange");
    $(this.fieldsWrapperTarget).slideToggle('fast');
  }

  eventDetailsTitleClick(event) {
    // console.log("eventDetailsTitleClick");
    $(this.eventDetailsWrapperTarget).slideToggle('fast');
    this.eventDetailsTitleChevronTarget.classList.toggle("arrow-up");
  }

  expandAllLinkClick(event) {
    // console.log("expandAllLinkClick");
    var chevrons = [
      this.advancedTitleChevronTarget,
      this.eventDetailsTitleChevronTarget,
      this.speakersTitleChevronTarget,
      this.breakoutTitleChevronTarget,
      this.scheduleTicketingChevronTarget
    ];
    chevrons.forEach(function(target) {
      // console.log(target);
      if (!target.classList.contains("arrow-up")) {
        target.click();
      };
    });
  }

  collapseAllLinkClick(event) {
    // console.log("collapseAllLinkClick");
    var chevrons = [
      this.advancedTitleChevronTarget,
      this.eventDetailsTitleChevronTarget,
      this.speakersTitleChevronTarget,
      this.breakoutTitleChevronTarget,
      this.scheduleTicketingChevronTarget
    ];
    chevrons.forEach(function(target) {
      // console.log(target);
      if (target.classList.contains("arrow-up")) {
        target.click();
      };
    });
  }

  kwivrr_provider_KwivrrChange() {
    // console.log("kwivrr_provider_KwivrrChange");
    this.kwivrrStreamingTarget.classList.remove("not-selected");
    this.kwivrrStreamingTarget.classList.add("selected");

    this.kwivrrMeetingTarget.classList.remove("selected");
    this.kwivrrMeetingTarget.classList.add("not-selected");

    this.kwivrrStreamingDescTarget.classList.remove("d-none");
    this.kwivrrStreamingDescTarget.classList.add("d-flex");

    this.kwivrrMeetingDescTarget.classList.remove("d-flex");
    this.kwivrrMeetingDescTarget.classList.add("d-none");

    this.btnClearProviderTarget.classList.remove("d-none");
    this.btnKwivrrProviderToggleSaveTarget.disabled = false;
  }

  kwivrr_provider_JitsiChange(event) {
    // console.log("kwivrr_provider_JitsiChange");
    this.kwivrrStreamingTarget.classList.remove("selected");
    this.kwivrrStreamingTarget.classList.add("not-selected");

    this.kwivrrMeetingTarget.classList.remove("not-selected");
    this.kwivrrMeetingTarget.classList.add("selected");

    this.kwivrrStreamingDescTarget.classList.remove("d-flex");
    this.kwivrrStreamingDescTarget.classList.add("d-none");

    this.kwivrrMeetingDescTarget.classList.remove("d-none");
    this.kwivrrMeetingDescTarget.classList.add("d-flex");

    this.btnClearProviderTarget.classList.remove("d-none");
    this.btnKwivrrProviderToggleSaveTarget.disabled = false;
  }

  providerRadioButtonTargetConnected(element) {
    // // // generated ids:
    // // zevents_event_provider_attributes_provider_type_youtube
    // // zevents_event_provider_attributes_provider_type_vimeo
    // // zevents_event_provider_attributes_provider_type_kwivrr
    // // zevents_event_provider_attributes_provider_type_zoom
    // // zevents_event_provider_attributes_provider_type_prezi
    // // zevents_event_provider_attributes_provider_type_jitsi
    // console.log("providerRadioButtonTargetConnected: ", element.id);
  }

  eventTypeToggleButtonTargetConnected(element) {
    // // // generated ids:
    // // zevents_event_event_type_physical
    // // zevents_event_event_type_digital
    // console.log("eventTypeToggleButtonTargetConnected: ", element.id);
  }
}
